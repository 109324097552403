import { useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { GET_SUBJECTS } from "./api";
import { useAppSelector } from "hooks/redux";
import PageHeader from "UI/PageHeader";
import { Button, Grid } from "@mui/material";
import t from "translate";
import Loader from "components/Loader";
import CreateSubjectModal from "./components/CreateSubjects";
import RoadmapCard from "../../../../components/Card";
import paths from "appConstants/RoutePaths";
import { usePermission } from "hooks/permission";

export type Topic = { _id: string; title: string; description: string };

type SubjectType = {
  _id: string;
  title: string;
  numberOfTopics: number;
};

type Props = {
  roadmapId: string;
};

function Subjects({ roadmapId }: Props) {
  const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);

  const center = useAppSelector((state) => state.center.center?._id);

  const { PermissionComponents } = usePermission();

  const { data, loading, refetch } = useQuery(GET_SUBJECTS, {
    variables: {
      center,
      roadmapId,
    },
  });

  const toggleCreateModalVisible = () =>
    setIsCreateModalVisible((prev) => !prev);

  const subjects: SubjectType[] = data?.getSubjects?.payload || [];

  useEffect(() => {
    refetch();
  }, []);

  return (
    <>
      {loading && <Loader />}
      <PageHeader title="Subjects" size="small">
        <PermissionComponents section="subjects" action="create">
          <Button
            variant="outlined"
            disabled={!roadmapId}
            onClick={toggleCreateModalVisible}
          >
            {t("create")}
          </Button>
        </PermissionComponents>
      </PageHeader>
      <Grid container spacing={2}>
        {subjects.map(({ title, _id, numberOfTopics }) => (
          <Grid item xs={12} md={4} lg={3} key={_id}>
            <RoadmapCard
              title={title}
              description=""
              _id={_id}
              path={paths.CENTER_SUBJECT_INFO}
              section="topics"
              unitsType="topics"
              numberOfUnits={numberOfTopics}
            />
          </Grid>
        ))}
      </Grid>
      <CreateSubjectModal
        onClose={toggleCreateModalVisible}
        open={isCreateModalVisible}
        refetch={refetch}
        roadmapId={roadmapId || ""}
      />
    </>
  );
}

export default Subjects;
