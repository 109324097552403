import { gql } from "@apollo/client";

export const UPDATE_ROADMAP = gql`
  mutation updateRoadmap($title: String!, $roadmapId: ID!, $center: ID!) {
    updateRoadmap(center: $center, title: $title, roadmapId: $roadmapId) {
      payload {
        _id
      }
    }
  }
`;
