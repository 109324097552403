import { createSlice } from "@reduxjs/toolkit";
import { IInitialState } from "interfaces/Redux";

const initialState: IInitialState = {
  center: null,
  groupId: null,
  permission: null,
  homeworkId: null,
  courseId: null,
  student: null,
  employee: null,
  roadmapId: null,
  subjectId: null,
  topicId: null,
};

const center = createSlice({
  name: "center",
  initialState,
  reducers: {
    addCenter(state, action) {
      state.center = action.payload;
    },
    removeCenter(state) {
      state.groupId = null;
      state.courseId = null;
      state.permission = null;
      state.homeworkId = null;
      state.student = null;
    },
    addGroupID(state, action) {
      state.groupId = action.payload;
    },
    addCourseID(state, action) {
      state.courseId = action.payload;
    },
    addPermission(state, action) {
      state.permission = action.payload;
    },
    addHomework(state, action) {
      state.homeworkId = action.payload;
    },
    addStudent(state, action) {
      state.student = action.payload;
    },
    addEmployee(state, action) {
      state.employee = action.payload;
    },
    addRoadmapId(state, action) {
      state.roadmapId = action.payload;
    },
    addSubjectId(state, action) {
      state.subjectId = action.payload;
    },
    addTopicId(state, action) {
      state.topicId = action.payload;
    },
  },
});

export default center.reducer;

export const {
  addCenter,
  removeCenter,
  addGroupID,
  addPermission,
  addHomework,
  addCourseID,
  addStudent,
  addEmployee,
  addRoadmapId,
  addSubjectId,
  addTopicId,
} = center.actions;
