import React, { useMemo, useState } from "react";

import t from "translate";
import { Box, Tab, Tabs } from "@mui/material";
import { usePermission } from "hooks/permission";

import { ABSENCES_TABS } from "./constants";

function Additions() {
  const [selectedTab, setSelectedTab] = useState(ABSENCES_TABS[0]?.key);

  const { PermissionRoutes } = usePermission();

  const ActiveTabElement = useMemo(() => {
    return ABSENCES_TABS.find((tab) => tab.key === selectedTab)?.Element;
  }, [selectedTab]);

  const allowedTabs = useMemo(() => {
    return PermissionRoutes(ABSENCES_TABS);
  }, [PermissionRoutes]);

  return (
    <>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={selectedTab}
          variant="scrollable"
          onChange={(_, activeTab) => setSelectedTab(activeTab)}
        >
          {allowedTabs.map(({ key, title }) => {
            return <Tab label={t(title)} key={key} value={key} />;
          })}
        </Tabs>
      </Box>
      <Box paddingY={4}>{ActiveTabElement && <ActiveTabElement />}</Box>
    </>
  );
}

export default Additions;
