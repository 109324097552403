import { useQuery } from "@apollo/client";
import { Button, Grid } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import t from "translate";
import PageHeader from "UI/PageHeader";
import { GET_TOPICS } from "./api";
import InnerLoader from "components/InnerLoader";

import CreateTopic from "./components/Create";
import { usePermission } from "hooks/permission";
import TopicCard from "./components/Card";
import NoData from "UI/NoData";

type Props = { center: string; subjectId: string };

type TopicType = {
  _id: string;
  title: string;
  description: string;
};

function Topics({ center, subjectId }: Props) {
  const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);

  const { data, loading, refetch } = useQuery(GET_TOPICS, {
    variables: {
      center,
      subjectId,
    },
  });

  const { isActionAllowed, PermissionComponents } = usePermission();

  const toggleCreateModalVisible = useCallback(() => {
    setIsCreateModalVisible((prev) => !prev);
  }, []);

  const topics: TopicType[] = data?.getTopicsBySubjectId?.payload || [];

  const isEditAllowed = useMemo(
    () => isActionAllowed({ section: "topics", action: "edit" }),
    [isActionAllowed]
  );

  const isDeleteAllowed = useMemo(
    () =>
      isActionAllowed({
        section: "topics",
        action: "delete",
      }),
    [isActionAllowed]
  );

  useEffect(() => {
    refetch();
  }, []);

  return (
    <>
      <PageHeader title="Topics" size="small">
        <PermissionComponents action="create" section="topics">
          <Button variant="outlined" onClick={toggleCreateModalVisible}>
            {t("Create topic")}
          </Button>
        </PermissionComponents>
      </PageHeader>
      {loading && <InnerLoader />}
      {topics.length ? (
        <Grid container spacing={2}>
          {topics.map(({ title, description, _id }) => (
            <Grid item xs={12} md={4} lg={3} key={_id}>
              <TopicCard
                _id={_id}
                description={description}
                title={title}
                isEditAllowed={isEditAllowed}
                isDeleteAllowed={isDeleteAllowed}
                center={center}
                refetch={refetch}
              />
            </Grid>
          ))}
        </Grid>
      ) : (
        <NoData />
      )}
      {isCreateModalVisible && (
        <CreateTopic
          onClose={toggleCreateModalVisible}
          open={isCreateModalVisible}
          refetch={refetch}
          subjectId={subjectId}
        />
      )}
    </>
  );
}

export default Topics;
