import { gql } from "@apollo/client";

export const DELETE_ROADMAP = gql`
  mutation deleteRoadmap($center: ID!, $roadmapId: ID!) {
    deleteRoadmap(center: $center, roadmapId: $roadmapId) {
      payload {
        _id
      }
    }
  }
`;
