import { ITabs } from "interfaces/Route";
import Subjects from "../tabs/Subjects";
import Actions from "../tabs/Actions";

export const ROADMAP_TABS: ITabs[] = [
  {
    title: "subjects",
    Element: Subjects,
    key: "subjects",
    section: "subjects",
    action: "view",
  },
  {
    title: "actions",
    Element: Actions,
    key: "roadmap",
    section: "roadmaps",
    action: "edit",
  },
];
