import React, { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Divider, Menu, MenuItem, Typography } from "@mui/material";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";

import { HEADER_DROPDOWN_ITEMS } from "components/Header/constants";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import t from "translate";
import { signOut } from "store/reducer/AuthSlice";
import { removeCenter } from "store/reducer/CenterSlice";
import { IDropdownRoute } from "interfaces/Route";

type Props = {
  open: boolean;
  onClose: () => void;
};

function HeaderDropdown({ open, onClose }: Props) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const lang = useAppSelector((store) => store.auth.lang);
  
  const signOutHandler = useCallback(() => {
    dispatch(signOut());
    dispatch(removeCenter());
    navigate("/");
  }, []);

  const onLinkClick = useCallback((path: string) => {
    navigate(path);
    onClose();
  }, []);

  const menuItems = useMemo(
    () =>
      HEADER_DROPDOWN_ITEMS.map(
        ({ key, path, title, icon }: IDropdownRoute) => (
          <div key={key}>
            <MenuItem sx={{ gap: 1 }} onClick={() => onLinkClick(path)}>
              {icon}
              <Typography textAlign="center">{t(title)}</Typography>
            </MenuItem>
            <Divider />
          </div>
        )
      ),
    [HEADER_DROPDOWN_ITEMS, lang]
  );

  return (
    <Menu
      sx={{ mt: 6 }}
      id="menu-header"
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={open}
      onClose={onClose}
    >
      {menuItems}
      <MenuItem onClick={signOutHandler} sx={{ gap: 1 }}>
        <LogoutOutlinedIcon />
        <Typography>{t("sign out")}</Typography>
      </MenuItem>
    </Menu>
  );
}

export default React.memo(HeaderDropdown);
