import React, { ChangeEvent, useEffect, useState } from "react";
import {
  ControllerRenderProps,
  FieldErrors,
  FieldValues,
} from "react-hook-form";
import {
  FilledInputProps,
  InputProps,
  OutlinedInputProps,
  TextField,
} from "@mui/material";

import t from "translate";
import { formatNumberInput } from "./helpers";

type Props = {
  label: string;
  disabled?: boolean;
  helperText?: string;
  errors: FieldErrors<FieldValues>;
  field: ControllerRenderProps<FieldValues, any>;
  isPercentage?: boolean;
  InputProps?:
    | Partial<FilledInputProps>
    | Partial<OutlinedInputProps>
    | Partial<InputProps>;
};

function NumberInput({
  field,
  errors,
  label,
  InputProps,
  disabled = false,
  helperText = "",
}: Props) {
  const [number, setNumber] = useState<string>("0");

  const handleInputChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const formattedNumber = formatNumberInput(event.target.value);

    setNumber(formattedNumber);
    field.onChange(formattedNumber.replace(/\s/g, ""));
    return;
  };

  useEffect(() => {
    if (field.value && field.value !== number) {
      setNumber(formatNumberInput(field.value));
    }
  }, [field.value]);

  return (
    <TextField
      {...field}
      type="text"
      value={number}
      label={t(label)}
      disabled={disabled}
      InputProps={InputProps}
      onChange={handleInputChange}
      error={!!errors[field.name]}
      helperText={!!errors[field.name] && t(helperText)}
    />
  );
}

export default NumberInput;
