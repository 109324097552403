import { gql } from "@apollo/client";

export const GET_TOPICS = gql`
  query getTopicsBySubjectId($center: ID!, $subjectId: ID!) {
    getTopicsBySubjectId(center: $center, subjectId: $subjectId) {
      payload {
        _id
        title
        description
      }
    }
  }
`;

export const DELETE_TOPIC = gql`
  mutation deleteTopic($center: ID!, $topicId: ID!) {
    deleteTopic(center: $center, topicId: $topicId) {
      payload {
        _id
      }
    }
  }
`;
export const UPDATE_TOPIC = gql`
  mutation updateTopic(
    $center: ID!
    $topicId: ID!
    $title: String
    $description: String
  ) {
    updateTopic(
      center: $center
      topicId: $topicId
      title: $title
      description: $description
    ) {
      payload {
        _id
      }
    }
  }
`;
