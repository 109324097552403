import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  colors,
  Typography,
} from "@mui/material";
import t from "translate";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import ConfirmModal from "components/ConfirmModal";
import { useMutation } from "@apollo/client";
import { DELETE_TOPIC } from "../../api";
import { toast } from "react-toastify";
import Loader from "components/Loader";
import UpdateTopic from "../Update";

type Props = {
  _id: string;
  title: string;
  description: string;
  center: string;
  isEditAllowed: boolean;
  isDeleteAllowed: boolean;
  refetch: any;
};

function TopicCard({
  _id,
  title,
  description,
  isDeleteAllowed,
  isEditAllowed,
  center,
  refetch,
}: Props) {
  const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
  const [isUpdateModalVisible, setIsUpdateModalVisible] = useState(false);

  const [deleteTopic, { data, loading }] = useMutation(DELETE_TOPIC);

  const toggleDeleteModalVisible = useCallback(
    () => setIsDeleteModalVisible((prev) => !prev),
    []
  );
  const toggleUpdateModalVisible = useCallback(
    () => setIsUpdateModalVisible((prev) => !prev),
    []
  );

  const onDelete = useCallback(() => {
    deleteTopic({
      variables: {
        center,
        topicId: _id,
      },
    });
  }, [_id]);

  useEffect(() => {
    if (!loading && data) {
      toast.success(t("Topic deleted successfully!"));
      refetch();
    }
  }, [data, loading]);

  return (
    <>
      {loading && <Loader />}
      <Card
        elevation={0}
        sx={{
          border: "1px solid " + colors.grey[300],
          boxShadow: "none",
        }}
      >
        <CardContent>
          <Typography gutterBottom variant="h5" component="div">
            {title}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {description}
          </Typography>
        </CardContent>
        <CardActions sx={{ display: "flex", justifyContent: "flex-end" }}>
          {isDeleteAllowed && (
            <Button
              size="small"
              startIcon={<DeleteOutlinedIcon />}
              color="error"
              onClick={toggleDeleteModalVisible}
              disabled={loading}
            >
              {t("delete")}
            </Button>
          )}

          {isEditAllowed && (
            <Button
              size="small"
              startIcon={<EditOutlinedIcon />}
              disabled={loading}
              onClick={toggleUpdateModalVisible}
            >
              {t("edit")}
            </Button>
          )}
        </CardActions>
      </Card>
      {isUpdateModalVisible && (
        <UpdateTopic
          _id={_id}
          center={center}
          description={description}
          title={title}
          open={isUpdateModalVisible}
          refetch={refetch}
          onClose={toggleUpdateModalVisible}
        />
      )}
      <ConfirmModal
        title="Are you sure what you want delete this topic"
        open={isDeleteModalVisible}
        onConfirm={onDelete}
        onClose={toggleDeleteModalVisible}
      />
    </>
  );
}

export default React.memo(TopicCard);
