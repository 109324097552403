import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  TextField,
} from "@mui/material";
import React, { useEffect } from "react";
import t from "translate";
import CloseIcon from "@mui/icons-material/Close";
import { Controller, FieldValues, useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { useAppSelector } from "hooks/redux";
import Loader from "components/Loader";
import { UPDATE_ROOM } from "./api";

type Props = {
  refetch: any;
  open: boolean;
  onClose: VoidFunction;
  title: string;
  roomId: string;
};

function UpdateRoomModal({ refetch, onClose, open, title, roomId }: Props) {
  const [updateRoom, { data, loading }] = useMutation(UPDATE_ROOM);

  const centerId = useAppSelector((state) => state.center.center?._id);

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();

  const onSubmit = ({ title }: FieldValues) => {
    updateRoom({
      variables: {
        title,
        center: centerId,
        roomId,
      },
    });
  };

  const handleClose = () => {
    reset({});
    onClose();
  };

  useEffect(() => {
    if (!loading && data) {
      refetch();
      handleClose();
    }
  }, [data, loading]);

  useEffect(() => {
    reset({ title });
  }, [title]);

  return (
    <>
      {loading && <Loader />}
      <Dialog open={open} onClose={handleClose}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <DialogTitle>{t("Update room")}</DialogTitle>
          <IconButton aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <DialogContent>
          <Controller
            name="title"
            rules={{
              required: true,
            }}
            control={control}
            render={({ field }) => {
              return (
                <FormControl fullWidth>
                  <TextField
                    required
                    {...field}
                    value={field.value || ""}
                    label={t("Room title")}
                    error={!!errors[field.name]}
                    helperText={
                      !!errors[field.name] && t("Please input the Room title!")
                    }
                  />
                </FormControl>
              );
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            fullWidth
            color="inherit"
            onClick={handleClose}
            variant="contained"
          >
            {t("cancel")}
          </Button>
          <Button
            fullWidth
            variant="contained"
            onClick={handleSubmit(onSubmit)}
          >
            {t("update")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default UpdateRoomModal;
