import { createSlice } from "@reduxjs/toolkit";
import { PaginationStateType } from "store/types/pagination";

const initialState: PaginationStateType = {
  admissionPending: 1,
  admissionDemoLesson: 1,
  admissionWillJoin: 1,
  courses: 1,
  groups: 1,
  students: 1,
  studentInfoPayments: 1,
  employees: 1,
  payments: 1,
  expenses: 1,
  absencesCurrentStudents: 1,
  absencesDemoLesson: 1,
  absencesArchive: 1,
  salaries: 1,
  roadmaps: 1,
};

const pagination = createSlice({
  name: "pagination",
  initialState,
  reducers: {
    setAdmissionPendingPage(state, action) {
      state.admissionPending = action.payload;
    },
    setAdmissionDemoLessonPage(state, action) {
      state.admissionDemoLesson = action.payload;
    },
    setAdmissionWillJoinPage(state, action) {
      state.admissionWillJoin = action.payload;
    },
    setCoursesPage(state, action) {
      state.courses = action.payload;
    },
    setGroupsPage(state, action) {
      state.groups = action.payload;
    },
    setStudentsPage(state, action) {
      state.students = action.payload;
    },
    setStudentInfoPaymentsPage(state, action) {
      state.studentInfoPayments = action.payload;
    },
    setEmployeesPage(state, action) {
      state.employees = action.payload;
    },
    setPaymentsPage(state, action) {
      state.payments = action.payload;
    },
    setExpensesPage(state, action) {
      state.expenses = action.payload;
    },
    setAbsencesCurrentStudentsPage(state, action) {
      state.absencesCurrentStudents = action.payload;
    },
    setAbsencesDemoLessonPage(state, action) {
      state.absencesDemoLesson = action.payload;
    },
    setAbsencesArchivePage(state, action) {
      state.absencesArchive = action.payload;
    },
    setSalariesPage(state, action) {
      state.salaries = action.payload;
    },
    setRoadmapsPage(state, action) {
      state.roadmaps = action.payload;
    },
  },
});

export default pagination.reducer;

export const paginationActions = pagination.actions;
