import { gql } from "@apollo/client";

export const GET_SUBJECT = gql`
  query getSubjectById($center: ID!, $subjectId: ID!) {
    getSubjectById(center: $center, subjectId: $subjectId) {
      payload {
        _id
        title
        numberOfTopics
      }
    }
  }
`;
