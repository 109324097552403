import { gql } from "@apollo/client";
import { permissionSections } from "utils/PermissionTypes";

export const GET_BASE_PERMISSION = gql`
  query getBasePermission{
    getBasePermission {
      payload {
        role
       ${permissionSections}
      }
    }
  }
`;

export const GET_PERMISSION_BY_ID = gql`
  query getPermissionById($permissionId:ID!) {
    getPermissionById(permissionId: $permissionId){
      payload{
        _id
        role
        ${permissionSections}
      }
      isPaymentExpired
    }
  }
`;

export const GET_ALL_PERMISSIONS = gql`
  query getPermissions($center: ID!) {
    getPermissions(center: $center) {
      payload{
        _id
        role
        isDeletable
        ${permissionSections}
      }
    }
  }
`;

export const GET_PERMISSION_BY_USER_ID = gql`
  query getPermissionByUserId($center: ID!, $user:ID!) {
    getPermissionByUserId(center: $center,user: $user){
      payload{
        center{
            _id
        }
        role
        ${permissionSections}
      }
      isPaymentExpired
    }
  }
`;
