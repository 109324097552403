import { useQuery } from "@apollo/client";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import t from "translate";
import { useAppSelector } from "hooks/redux";
import Loader from "components/Loader";
import { ITabs } from "interfaces/Route";
import { usePermission } from "hooks/permission";
import { SUBJECT_TABS } from "./contants";
import { GET_SUBJECT } from "./api";

function SubjectInfo() {
  const [selectedTab, setSelectedTab] = useState(SUBJECT_TABS[0]?.key);
  const [allowedTabs, setAllowedTabs] = useState<ITabs[]>([]);

  const { id } = useParams();
  const centerId = useAppSelector((state) => state.center.center?._id);

  const { data, loading, refetch } = useQuery(GET_SUBJECT, {
    variables: {
      center: centerId,
      subjectId: id,
    },
  });

  const { PermissionRoutes } = usePermission();
  const permission = useAppSelector((state) => state.center.permission);

  const ActiveTabElement = SUBJECT_TABS.find(
    (tab) => tab.key === selectedTab
  )?.Element;

  const subject = data?.getSubjectById?.payload || {};

  useEffect(() => {
    if (!allowedTabs.length) {
      const tabs = PermissionRoutes(SUBJECT_TABS);
      setAllowedTabs(tabs);
      setSelectedTab(tabs[0]?.key);
    }
  }, [permission]);

  return (
    <>
      {loading && <Loader />}
      <Box display="flex" flexDirection="column" gap={1}>
        <Typography variant="h4" mb={2}>
          {subject?.title || "Unknown"}
        </Typography>
      </Box>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={selectedTab}
          variant="scrollable"
          onChange={(_, activeTab) => setSelectedTab(activeTab)}
        >
          {allowedTabs.map(({ key, title }) => {
            return <Tab label={t(title)} key={key} value={key} />;
          })}
        </Tabs>
      </Box>
      <Box paddingY={4}>
        {ActiveTabElement && (
          <ActiveTabElement
            center={centerId}
            subjectId={id}
            title={subject?.title || ""}
            refetch={refetch}
          />
        )}
      </Box>
    </>
  );
}

export default SubjectInfo;
