import { useEffect } from "react";
import { useLazyQuery } from "@apollo/client";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Controller, FieldValues, useForm } from "react-hook-form";
import { Box, Button, FormControl, Typography, colors } from "@mui/material";
import t from "translate";
import { SIGN_IN } from "./api";
import PhoneInput from "UI/PhoneInput";
import Loader from "components/Loader";
import paths from "appConstants/RoutePaths";
import AuthWrapper from "UI/AuthWrapper";
import { formatPhoneToSubmit } from "UI/PhoneInput/helper";
import { signInAction } from "store/reducer/AuthSlice";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import PasswordInput from "UI/PasswordInput";

function SignIn() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const centerParamId = searchParams.get("id");
  const centerId = useAppSelector((state) => state.center.center?._id);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const [signIn, { loading, data }] = useLazyQuery(SIGN_IN);

  const handleSingIn = ({ phone, password }: FieldValues) => {
    if (password && phone) {
      signIn({
        variables: {
          phone: formatPhoneToSubmit(phone),
          password,
        },
        fetchPolicy: "network-only",
      });
    }
  };

  useEffect(() => {
    const navigationPath =
      centerId && centerParamId ? paths.CENTER_INFO : paths.PROFILE;
    if (!loading && data) {
      dispatch(signInAction(data.signIn));
      navigate(navigationPath);
    }
  }, [loading, data]);

  return (
    <AuthWrapper
      title="sign in"
      subLink="sign up"
      subLinkPath={paths.SIGN_UP}
      subText="you don't have account yet?"
    >
      {loading && <Loader />}
      <Box
        gap={3}
        display="flex"
        component="form"
        flexDirection="column"
        onSubmit={handleSubmit(handleSingIn)}
      >
        <Controller
          name="phone"
          rules={{
            required: true,
            min: 9,
          }}
          control={control}
          render={({ field }) => {
            return (
              <FormControl fullWidth>
                <PhoneInput field={field} errors={errors} />
              </FormControl>
            );
          }}
        />
        <Controller
          name="password"
          rules={{ required: true }}
          control={control}
          render={({ field }) => (
            <FormControl fullWidth>
              <PasswordInput field={field} errors={errors} />
            </FormControl>
          )}
        />
        <Typography color={colors.blue[700]} variant="body2" textAlign="right">
          <Link to={paths.RESET_PHONE_VALIDATION}>{t("reset password")}</Link>
        </Typography>
        <Button size="large" type="submit" fullWidth variant="contained">
          {t("sign in")}
        </Button>
      </Box>
    </AuthWrapper>
  );
}

export default SignIn;
