import React, { ReactNode } from "react";
import { Box, Divider, Typography } from "@mui/material";
import t from "translate";
import { pxToRem } from "utils";
import PageHeaderStyle from "./PageHeader.style";

interface Props {
  title: string;
  suffix?: JSX.Element;
  divider?: boolean;
  children?: ReactNode;
  translateTitle?: boolean;
  size?: "small" | "medium";
}

const PageHeader = ({
  title,
  suffix,
  divider = true,
  size = "medium",
  translateTitle = true,
  children,
}: Props) => {
  return (
    <PageHeaderStyle>
      <Box
        display="flex"
        flexWrap="wrap"
        gap={pxToRem(14)}
        sx={!divider ? { marginBottom: pxToRem(20) } : {}}
        alignItems="center"
        justifyContent="space-between"
      >
        <Box display="flex" flexWrap="wrap" gap={1}>
          <Typography variant={size === "small" ? "h5" : "h4"}>
            {translateTitle ? t(title) : title}
          </Typography>
          {suffix}
        </Box>
        <Box display="flex" flexWrap="wrap" gap={pxToRem(14)}>
          {children}
        </Box>
      </Box>
      {divider && (
        <Divider sx={{ marginBottom: pxToRem(40), marginTop: pxToRem(14) }} />
      )}
    </PageHeaderStyle>
  );
};

export default React.memo(PageHeader);
