import {
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import React, { useMemo } from "react";
import { ReactComponent as Logo } from "assets/images/logo.svg";
import { HEADER_ROUTES } from "components/Header/constants";
import t from "translate";
import { IDropdownRoute } from "interfaces/Route";
import { useAppSelector } from "hooks/redux";

type Props = {
  open: boolean;
  onClose: () => void;
  onLinkClick: (path: string) => void;
};

function HeaderDrawer({ open, onClose, onLinkClick }: Props) {
  const lang = useAppSelector((store) => store.auth.lang);

  const listItems = useMemo(
    () =>
      HEADER_ROUTES.map(({ title, path, key, icon }: IDropdownRoute) => (
        <ListItem key={key} disablePadding>
          <ListItemButton onClick={() => onLinkClick(path)}>
            <ListItemIcon>{icon}</ListItemIcon>
            <ListItemText primary={t(title)} />
          </ListItemButton>
        </ListItem>
      )),
    [HEADER_ROUTES, lang]
  );

  return (
    <Drawer open={open} onClose={onClose}>
      <Box pt={2} px={2}>
        <Logo width={150} />
      </Box>
      <Divider sx={{ my: 3 }} />
      <List sx={{ width: 350 }}>{listItems}</List>
    </Drawer>
  );
}

export default React.memo(HeaderDrawer);
