import { ITabs } from "interfaces/Route";
import Salaries from "../tabs/Salaries";
import Roadmap from "../tabs/Roadmap";
import Rooms from "../tabs/Rooms";
import Discounts from "../tabs/Discounts";

export const ABSENCES_TABS: ITabs[] = [
  {
    title: "salaries",
    Element: Salaries,
    key: "salaries",
    section: "salaries",
    action: "view",
  },
  {
    title: "Roadmap",
    Element: Roadmap,
    key: "roadmap",
    section: "roadmaps",
    action: "view",
  },
  {
    title: "rooms",
    Element: Rooms,
    key: "rooms",
    section: "rooms",
    action: "view",
  },
  {
    title: "discounts",
    Element: Discounts,
    key: "discounts",
    section: "discounts",
    action: "view",
  },
];
