export const getOnlyUpdatedFields = function (
  oldFields: any,
  updatedFields: any
) {
  let result = {};
  for (let x in updatedFields) {
    if (oldFields[x] !== updatedFields[x]) {
      result = { ...result, [x]: updatedFields[x] };
    }
  }
  return result;
};
