import t from "translate";
import Style from "./Style";
import { Box, Button, Typography } from "@mui/material";
import { pxToRem } from "utils";
import React, { useCallback, useState } from "react";
import ConfirmModal from "components/ConfirmModal";
import { CSSProperties } from "styled-components";
import ConfirmPassword from "components/ConfirmPassword";

export type ActionTypes = "info" | "danger" | "warning";
export type VariantTypes = "contained" | "outlined" | "text";

type Props = {
  title: string;
  btnLabel: string;
  type?: ActionTypes;
  description: string;
  style?: CSSProperties;
  onClick: VoidFunction;
  btnClassName?: string;
  variant?: VariantTypes;
  confirmPassword?: boolean;
  isButtonDisabled?: boolean;
};

const buttonColors = {
  danger: "error",
  info: "primary",
  warning: "warning",
};

function ActionCard({
  title,
  style,
  onClick,
  btnLabel,
  type = "info",
  description,
  btnClassName,
  variant = "outlined",
  confirmPassword = false,
  isButtonDisabled = false,
}: Props) {
  const [isDeleteVisible, setIsDeleteVisible] = useState<boolean>(false);

  const toggleDeleteModalVisible = useCallback(() => {
    setIsDeleteVisible((prev) => !prev);
  }, []);

  const handleConfirm = useCallback(() => {
    if (type === "danger") {
      toggleDeleteModalVisible();
    } else {
      onClick();
    }
  }, [onClick, toggleDeleteModalVisible, type]);

  return (
    <Style type={type} style={style}>
      <Box
        display="flex"
        className="card"
        gap={pxToRem(14)}
        alignItems="center"
        justifyContent="space-between"
      >
        <Box flex="1">
          <Typography fontSize={pxToRem(17)}>{t(title)}</Typography>
          <Typography variant="body2" color="grey.700">
            {t(description)}
          </Typography>
        </Box>
        {btnLabel &&
          (confirmPassword ? (
            <ConfirmPassword onConfirm={handleConfirm}>
              <Button
                className={btnClassName}
                variant={variant}
                sx={{ paddingX: pxToRem(30) }}
                color={buttonColors[type] as any}
              >
                {t(btnLabel)}
              </Button>
            </ConfirmPassword>
          ) : (
            <Button
              onClick={handleConfirm}
              className={btnClassName}
              variant={variant}
              sx={{ paddingX: pxToRem(30) }}
              color={buttonColors[type] as any}
              disabled={isButtonDisabled}
            >
              {t(btnLabel)}
            </Button>
          ))}
      </Box>
      {type === "danger" && (
        <ConfirmModal
          open={isDeleteVisible}
          onConfirm={onClick}
          onClose={toggleDeleteModalVisible}
        />
      )}
    </Style>
  );
}

export default React.memo(ActionCard);
