import {
  Card,
  CardActionArea,
  CardContent,
  colors,
  Stack,
  Typography,
} from "@mui/material";

import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";
import CalculateOutlinedIcon from "@mui/icons-material/CalculateOutlined";
import PercentOutlinedIcon from "@mui/icons-material/PercentOutlined";
import t from "translate";

export type SalaryVariantsType = "fixed" | "percent" | "formula";

type Props = {
  type: SalaryVariantsType;
  selectedType: SalaryVariantsType;
  onSelect: (type: SalaryVariantsType) => void;
};

const activeCardStyle = {
  bgcolor: colors.blue[50],
  borderColor: colors.blue[400],
};

const icons = {
  fixed: <AttachMoneyOutlinedIcon fontSize="large" />,
  percent: <PercentOutlinedIcon fontSize="large" />,
  formula: <CalculateOutlinedIcon fontSize="large" />,
};

const SalaryTypeCard = ({ onSelect, type, selectedType }: Props) => {
  const isSalaryTypeSelected = type === selectedType;
  return (
    <Card sx={[isSalaryTypeSelected ? activeCardStyle : {}, { width: "100%" }]}>
      <CardActionArea onClick={() => onSelect(type)}>
        <CardContent sx={{ py: 3 }}>
          <Stack alignItems="center" justifyContent="center" mb={1}>
            {icons[type]}
          </Stack>
          <Typography textAlign="center" color="GrayText" variant="body1">
            {t(type)}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default SalaryTypeCard;
