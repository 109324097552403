import React from "react";
import { Controller, Control, FieldValues, FieldErrors } from "react-hook-form";
import {
  Autocomplete,
  FormControl,
  FormHelperText,
  TextField,
} from "@mui/material";
import t from "translate";
import cities from "jsons/cities.json";

type Props = {
  control: Control<FieldValues, any>;
  defaultValue?: string;
  errors: FieldErrors<FieldValues>;
};

function CitySelect({ control, errors, defaultValue }: Props) {
  return (
    <Controller
      name="city"
      rules={{ required: true }}
      control={control}
      render={({ field }) => (
        <FormControl fullWidth>
          <Autocomplete
            {...field}
            options={cities.cities}
            onChange={(_, { value }) => field.onChange(value)}
            getOptionLabel={(option) => option.label}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t("city")}
                error={!!errors[field.name]}
                variant="outlined"
              />
            )}
            renderOption={(props, option) => (
              <li {...props} key={option.key}>
                {option.label}
              </li>
            )}
          />
          {errors[field.name] && (
            <FormHelperText className="Mui-error">
              {t("Please select your city!")}
            </FormHelperText>
          )}
        </FormControl>
      )}
    />
  );
}

export default CitySelect;
