import { gql } from "@apollo/client";

export const GET_ALL_SALARIES = gql`
  query getSalariesByCenterId($center: ID!, $page: Int, $size: Int) {
    getSalariesByCenterId(center: $center, page: $page, size: $size) {
      payload {
        _id
        amount
        title
        type
        formula
      }
      totalPages
    }
  }
`;

export const UPDATE_SALARY = gql`
  mutation updateSalary(
    $salaryId: ID!
    $data: SalaryUpdateInput
    $center: ID!
  ) {
    updateSalary(salaryId: $salaryId, data: $data, center: $center) {
      payload {
        title
        amount
      }
    }
  }
`;

export const DELETE_SALARY = gql`
  mutation deleteSalary($salaryId: ID!, $centerId: ID!) {
    deleteSalary(salaryId: $salaryId, centerId: $centerId)
  }
`;
