import { gql } from "@apollo/client";

export const CREATE_DISCOUNT = gql`
  mutation createDiscount($center: ID!, $title: String!, $percentage: Float!) {
    createDiscount(center: $center, title: $title, percentage: $percentage) {
      payload {
        _id
      }
    }
  }
`;
