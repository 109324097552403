import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  TextField,
} from "@mui/material";
import React, { useEffect } from "react";
import t from "translate";
import CloseIcon from "@mui/icons-material/Close";
import { Controller, FieldValues, useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { UPDATE_TOPIC } from "./api";
import Loader from "components/Loader";
import { getOnlyUpdatedFields } from "helpers/update";

type Props = {
  _id: string;
  title: string;
  description: string;
  center: string;
  refetch: any;
  open: boolean;
  onClose: VoidFunction;
};

function UpdateTopic({
  _id,
  title,
  description,
  center,
  refetch,
  onClose,
  open,
}: Props) {
  const [updateTopic, { data, loading }] = useMutation(UPDATE_TOPIC);

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();

  const onSubmit = (data: FieldValues) => {
    const updatedFields = getOnlyUpdatedFields({ title, description }, data);
    updateTopic({
      variables: {
        topicId: _id,
        center,
        ...updatedFields,
      },
    });
  };

  const handleClose = () => {
    reset({});
    onClose();
  };

  useEffect(() => {
    reset({ title, description });
  }, []);

  useEffect(() => {
    if (!loading && data) {
      refetch();
      handleClose();
    }
  }, [data, loading]);

  return (
    <>
      {loading && <Loader />}
      <Dialog open={open} onClose={handleClose}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <DialogTitle>{t("Update topic")}</DialogTitle>
          <IconButton aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <DialogContent>
          <Box display="flex" gap={2} flexDirection="column">
            <Controller
              name="title"
              rules={{
                required: true,
              }}
              control={control}
              render={({ field }) => {
                return (
                  <FormControl fullWidth>
                    <TextField
                      required
                      {...field}
                      value={field.value || ""}
                      label={t("Topic title")}
                      error={!!errors[field.name]}
                      helperText={
                        !!errors[field.name] &&
                        t("Please input the topic title!")
                      }
                    />
                  </FormControl>
                );
              }}
            />
            <Controller
              name="description"
              rules={{
                required: true,
              }}
              control={control}
              render={({ field }) => {
                return (
                  <FormControl fullWidth>
                    <TextField
                      required
                      {...field}
                      multiline
                      rows={6}
                      value={field.value || ""}
                      label={t("Topic description")}
                      error={!!errors[field.name]}
                      helperText={
                        !!errors[field.name] &&
                        t("Please input the topic descriptions!")
                      }
                    />
                  </FormControl>
                );
              }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            fullWidth
            color="inherit"
            onClick={handleClose}
            variant="contained"
          >
            {t("cancel")}
          </Button>
          <Button
            fullWidth
            variant="contained"
            onClick={handleSubmit(onSubmit)}
          >
            {t("update")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default UpdateTopic;
