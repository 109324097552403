import { gql } from "@apollo/client";

export const CHECK_PASSWORD = gql`
  query signIn($phone: String!, $password: String!) {
    signIn(user: { phone: $phone, password: $password }) {
      token
      user {
        _id
        name
      }
    }
  }
`;
