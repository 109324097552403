import paths from "appConstants/RoutePaths";
import { IDropdownRoute } from "interfaces/Route";

import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import RecentActorsOutlinedIcon from "@mui/icons-material/RecentActorsOutlined";
// import ChecklistRtlOutlinedIcon from "@mui/icons-material/ChecklistRtlOutlined";
import CreditScoreOutlinedIcon from "@mui/icons-material/CreditScoreOutlined";
import EventNoteOutlinedIcon from "@mui/icons-material/EventNoteOutlined";

const { SCHEDULE, HOMEWORKS, PAYMENTS, PROFILE, CENTERS } = paths;

export const HEADER_ROUTES: IDropdownRoute[] = [
  {
    title: "schedule",
    path: SCHEDULE,
    key: "agenda",
    icon: <EventNoteOutlinedIcon />,
  },
  // {
  //   title: "homeworks",
  //   path: HOMEWORKS,
  //   key: "homework",
  //   icon: <ChecklistRtlOutlinedIcon />,
  // },
  {
    title: "payments",
    path: PAYMENTS,
    key: "payments",
    icon: <CreditScoreOutlinedIcon />,
  },
];

export const HEADER_DROPDOWN_ITEMS: IDropdownRoute[] = [
  {
    title: "profile",
    key: "profile",
    path: PROFILE,
    icon: <PersonOutlinedIcon />,
  },
  {
    title: "centers",
    key: "centers",
    path: CENTERS,
    icon: <RecentActorsOutlinedIcon />,
  },
];
