import { gql } from "@apollo/client";

export const DELETE_DISCOUNT = gql`
  mutation deleteDiscount($center: ID!, $discountId: ID!) {
    deleteDiscount(center: $center, discountId: $discountId) {
      payload {
        _id
      }
    }
  }
`;

export const GET_PAGINATED_DISCOUNTS = gql`
  query getPaginatedDiscounts($center: ID!, $page: Int) {
    getPaginatedDiscounts(center: $center, page: $page) {
      payload {
        _id
        title
        percentage
      }
      totalPages
    }
  }
`;
