import ReactDOM from "react-dom/client";
import App from "./App";
import { ApolloProvider } from "@apollo/client";
import client from "./graphql";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "store";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { Box, CircularProgress, ThemeProvider } from "@mui/material";
import { theme } from "theme";
import { Suspense } from "react";
import "normalize.css";
import "react-toastify/dist/ReactToastify.css";
import "styles/GlobalStyle.css";
import ErrorBoundary from "UI/ErrorBoundary";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <ApolloProvider client={client}>
    <Provider store={store}>
      <BrowserRouter>
        <ErrorBoundary>
          <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <Suspense
                fallback={
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    height="80vh"
                  >
                    <CircularProgress />
                  </Box>
                }
              >
                <App />
              </Suspense>
            </LocalizationProvider>
          </ThemeProvider>
        </ErrorBoundary>
      </BrowserRouter>
    </Provider>
  </ApolloProvider>
);
