import { Link } from "react-router-dom";
import { Box, Typography, colors } from "@mui/material";

import t from "translate";
import AuthWrapperStyle from "./Style";
import { TChildren } from "interfaces";
import { ReactComponent as AuthIcon } from "./assets/auth.svg";

type Props = {
  children: TChildren;
  title?: string;
  subTitle?: string;
  subText?: string;
  subLink?: string;
  subLinkPath?: string;
  supLinkText?: string;
  supLinkPath?: string;
  supLinkIcon?: JSX.Element;
};

const AuthWrapper = (props: Props) => {
  const {
    children,
    title = "",
    subTitle = "",
    subText = "",
    subLink = "",
    subLinkPath = "",
    supLinkText,
    supLinkPath = "",
    supLinkIcon,
  } = props;
  return (
    <AuthWrapperStyle>
      <Box display="flex" className="wrapper">
        <Box gap={2} className="auth-wrapper__block auth-wrapper__slider-side">
          <AuthIcon className="auth__image" />
          <Typography color="grey.100" variant="h4">
            {t("looking for a training center?")}
          </Typography>
          <Typography color="grey.200" variant="body1" maxWidth={400}>
            {t(
              "we help you easily find learning centers, easily track payments and tasks"
            )}
          </Typography>
        </Box>
        <div className="auth-wrapper__block">
          <Box
            display="flex"
            className="wrapper"
            flexDirection="column"
            justifyContent="space-between"
            alignItems="center"
          >
            <div></div>
            <div className="form-side">
              {supLinkText && (
                <Link
                  to={supLinkPath}
                  style={{ marginBottom: 24, display: "block" }}
                >
                  <Box display="flex" alignItems="center" gap={1}>
                    <Typography
                      color={colors.blue[700]}
                      variant="body1"
                      height="20px"
                    >
                      {supLinkIcon}
                    </Typography>
                    <Typography color={colors.blue[700]} variant="body1">
                      {t(supLinkText)}
                    </Typography>
                  </Box>
                </Link>
              )}
              <Typography variant="h4" mb={subTitle ? 1 : 3}>
                {t(title)}
              </Typography>
              {subTitle && (
                <Typography mb={3} variant="subtitle2">
                  {subTitle}
                </Typography>
              )}
              {children}
              <Box mt={2} gap={1} display="flex" alignItems="center">
                <Typography color="grey.900" variant="body2">
                  {t(subText)}
                </Typography>
                <Typography color={colors.blue[700]} variant="body1">
                  <Link to={subLinkPath}>{t(subLink)}</Link>
                </Typography>
              </Box>
            </div>
            <div></div>
          </Box>
        </div>
      </Box>
    </AuthWrapperStyle>
  );
};

export default AuthWrapper;
