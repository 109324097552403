import { gql } from "@apollo/client";

export const DELETE_SUBJECT = gql`
  mutation deleteSubject($center: ID!, $subjectId: ID!) {
    deleteSubject(center: $center, subjectId: $subjectId) {
      payload {
        _id
      }
    }
  }
`;
