import React from "react";
import { StyledSidebar } from "./Style";
import { Link, useLocation } from "react-router-dom";
import { TChildren } from "interfaces";
import { usePermission } from "hooks/permission";
import t from "translate";
import { Box, Tooltip, Typography } from "@mui/material";
import Container from "UI/Container";
import { useAppSelector } from "hooks/redux";
import SIDEBAR_ROUTES from "./constants";

interface IProps {
  children: TChildren | any;
}

const Sidebar = ({ children }: IProps) => {
  const { pathname } = useLocation();
  const { PermissionRoutes } = usePermission();

  const userRole = useAppSelector((state) => state.center.permission?.role);

  if (!pathname.includes("/center/")) {
    return children;
  }

  const allowedRoutes = PermissionRoutes(SIDEBAR_ROUTES);

  if (userRole === "visitor") {
    return <Container>{children}</Container>;
  }

  return (
    <StyledSidebar>
      <Box display="flex">
        <Box className="sidebar" display="flex" flexDirection="column" gap={1}>
          {allowedRoutes.map(({ key, path, title, icon }) => (
            <Tooltip title={t(title)} key={key} arrow placement="right">
              <Link
                className={`sidebar__link ${
                  pathname === path ? "sidebar__link--active" : ""
                } ${key === "settings" ? "sidebar__link--setting" : ""}`}
                to={path}
                key={key}
              >
                <Box
                  className="sidebar__link-inner"
                  display="flex"
                  gap={1}
                  alignItems="center"
                >
                  {icon}
                  <Typography
                    className="sidebar__link-text"
                    variant="subtitle1"
                  >
                    {t(title)}
                  </Typography>
                </Box>
              </Link>
            </Tooltip>
          ))}
        </Box>
        <div className="sidebar__child-side">
          <Container>{children}</Container>
        </div>
      </Box>
    </StyledSidebar>
  );
};
export default React.memo(Sidebar);
