import { useLazyQuery } from "@apollo/client";
import { useCallback, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "../redux";
import { GET_PERMISSION_BY_USER_ID } from "graphql/Query/Permission";
import { addPermission } from "store/reducer/CenterSlice";
import CenterPaymentContactButton from "components/CenterPaymentContactButton";

interface IAction {
  section: string;
  action: string;
}

interface ISection extends IAction {
  children: JSX.Element | JSX.Element[] | any;
  viewComponent?: JSX.Element;
}

export const usePermission = () => {
  const userId = useAppSelector((store) => store.auth.user?._id);
  const centerId = useAppSelector((store) => store.center.center?._id);
  const permission = useAppSelector((store) => store.center.permission);

  const dispatch = useDispatch();

  const [getPermission, { data, loading }] = useLazyQuery(
    GET_PERMISSION_BY_USER_ID
  );

  const getUpdatedPermissions = useCallback(() => {
    getPermission({
      variables: {
        center: centerId,
        user: userId,
      },
    });
  }, [centerId, userId]);

  useEffect(() => {
    if (centerId && !loading) {
      getUpdatedPermissions();
    }
  }, [centerId]);

  useEffect(() => {
    if (
      !loading &&
      data &&
      JSON.stringify(data.getPermissionByUserId) !==
        JSON.stringify(permissionSections)
    ) {
      const permission = data.getPermissionByUserId;
      dispatch(
        addPermission({
          ...permission?.payload,
          isPaymentExpired: permission?.isPaymentExpired,
        })
      );
    }
  }, [data, loading]);

  const permissionSections: any = useMemo(
    () => permission?.sections ?? {},
    [permission]
  );

  const PermissionComponents = useCallback(
    ({ section, action, children }: ISection) => {
      const selectedSection = permissionSections[section] ?? {};
      if (action === "view") {
        return selectedSection[action] ?? false ? children : <></>;
      }

      if (permission?.isPaymentExpired && action === "create") {
        return <CenterPaymentContactButton />;
      }

      if ((!permission?.isPaymentExpired && selectedSection[action]) ?? false) {
        return children;
      }

      return <></>;
    },
    [permissionSections, permission]
  );

  const isActionAllowed = useCallback(
    ({ action, section }: IAction) => {
      const selectedSection = permissionSections[section] ?? {};
      return !permission?.isPaymentExpired && selectedSection[action];
    },
    [permission?.isPaymentExpired, permissionSections]
  );

  const PermissionRoutes = (routes: any[]) => {
    const allowedRoutes = routes.filter((route) => {
      const permissionSection = permissionSections[route.section] ?? {};
      const permissionExtraSection =
        permissionSections[route.extraSection] ?? {};
      return (
        permissionSection[route.action] ||
        permissionExtraSection[route.action] ||
        false
      );
    });
    return allowedRoutes;
  };

  const isSectionsExist = useMemo(
    () => !!Object.keys(permissionSections).length,
    [permissionSections]
  );

  return {
    PermissionComponents,
    PermissionRoutes,
    isActionAllowed,
    loading,
    isSectionsExist,
  };
};
