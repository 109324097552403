type SalaryShortcutType = {
  key: string;
  description: string;
  demoValue: number;
};

const SALARY_SHORTCUTS: SalaryShortcutType[] = [
  {
    key: "MC-APGS",
    description:
      "Amount of payments by paid students in each group where the employee is the teacher will be calculated individually. Only the amount paid by students will be considered, not the expected amount.",
    demoValue: 950_000,
  },
  {
    key: "MC-AEPGS",
    description:
      "Use this key to calculate salary based on the expected amount of payments in group.",
    demoValue: 3_000_000,
  },
  {
    key: "MC-NGS",
    description:
      "The number of students in the group will be counted, excluding the students participating in the demo lesson.",
    demoValue: 8,
  },
  {
    key: "MC-NPGS",
    description:
      "It will be equal to the number of students who paid the monthly payment in group. ",
    demoValue: 3,
  },
  {
    key: "MC-NCS",
    description:
      "Count all currently enrolled students in center. Do not include students who are in the process of admission.",
    demoValue: 348,
  },
  {
    key: "MC-NPCS",
    description:
      "Number of students who have made their monthly payments in the center.",
    demoValue: 130,
  },
  {
    key: "MC-APCS",
    description: "The total amount paid at this center for this month.",
    demoValue: 3_900_00,
  },
  {
    key: "MC-NECS",
    description:
      "Total number of new students registered for the group, including those who attended the first lesson.",
    demoValue: 30,
  },
  {
    key: "MC-TSLG",
    description: "Teacher's skipped lessons count.",
    demoValue: 3,
  },
  {
    key: "MC-NEC",
    description:
      "Total number of employees in the center. The center owner will not be counted!",
    demoValue: 12,
  },
  {
    key: "MC-EEXC",
    description:
      "The employee's experience will be considered from the time they joined this center.",
    demoValue: 1.5,
  },
  {
    key: "MC-EEXT",
    description:
      "The experience of an employee is derived from the employee's information.",
    demoValue: 2.3,
  },
];

export default SALARY_SHORTCUTS;
