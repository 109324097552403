enum colors {
  borderGray = "#eee",
  textGray = "#808080",
  mainBlue = "#2a6eff",
  textBlack = "#131A29",
  white = "#FFFFFF",
  boxShadow = "#edeff0",
  imageBg = "#00000050",
  red = "#cd3737",
  green = "#0F8C0D",
  yellow = "#ffc267",
  darkRed = "#c42119",
  purple = "#5849c9",
  chartPink = "#b800d8",
  chartGreen = "#02b2af",
}

export default colors;
