import { gql } from "@apollo/client";

export const CREATE_SUBJECT = gql`
  mutation createSubject($center: ID!, $roadmapId: ID!, $title: String!) {
    createSubject(center: $center, roadmapId: $roadmapId, title: $title) {
      payload {
        _id
        title
      }
    }
  }
`;
