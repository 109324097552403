import { Button, Grid } from "@mui/material";
import React, { useState } from "react";
import t from "translate";
import PageHeader from "UI/PageHeader";
import CreateRoomModal from "./components/CreateModal";
import { usePermission } from "hooks/permission";
import { useQuery } from "@apollo/client";
import GET_ROOMS from "./api";
import InnerLoader from "components/InnerLoader";
import RoomType from "./types";
import { useAppSelector } from "hooks/redux";
import RoomCard from "./components/RoomCard";

import AddRoundedIcon from "@mui/icons-material/AddRounded";

function Rooms() {
  const [isCreateModalVisible, setIsCreateModalVisible] = useState(false);

  const centerId = useAppSelector((state) => state.center.center?._id);

  const { PermissionComponents } = usePermission();
  const { data, loading, refetch } = useQuery(GET_ROOMS, {
    variables: { center: centerId },
  });

  const toggleCreateModalVisible = () =>
    setIsCreateModalVisible((prev) => !prev);

  const rooms: RoomType[] = data?.getRooms?.payload || [];

  return (
    <>
      <PageHeader title="rooms">
        <PermissionComponents section="rooms" action="create">
          <Button
            variant="outlined"
            startIcon={<AddRoundedIcon />}
            onClick={toggleCreateModalVisible}
          >
            {t("Create new")}
          </Button>
        </PermissionComponents>
      </PageHeader>
      {loading ? (
        <InnerLoader />
      ) : (
        <Grid container spacing={2}>
          {rooms.map(({ _id, title, numberOfGroups }) => (
            <Grid key={_id} item lg={3}>
              <RoomCard
                title={title}
                numberOfGroups={numberOfGroups}
                _id={_id}
                center={centerId || ""}
                refetch={refetch}
              />
            </Grid>
          ))}
        </Grid>
      )}
      {isCreateModalVisible && (
        <CreateRoomModal
          open={isCreateModalVisible}
          onClose={toggleCreateModalVisible}
          refetch={refetch}
        />
      )}
    </>
  );
}

export default Rooms;
