import { pxToRem } from "utils";
import styled from "styled-components";
import {
  HEADER_HEIGHT,
  HEADER_RESPONSIVE_HEIGHT,
} from "components/Sidebar/constants/styles";

export default styled.nav`
  padding: ${pxToRem(16)} ${pxToRem(24)};
  height: ${pxToRem(HEADER_HEIGHT)};

  @media print {
    display: none;
  }

  @media (max-width: 900px) {
    height: ${pxToRem(HEADER_RESPONSIVE_HEIGHT)};
  }
`;
