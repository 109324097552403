import { gql } from "@apollo/client";

export const GET_ALL_FINISHED_SESSIONS = gql`
  query getAllSessionsByDate($center: ID!, $date: String!) {
    getAllSessionsByDate(center: $center, date: $date) {
      payload {
        _id
        theme
        topic {
          title
        }
        teacher {
          name
          image
        }
        startedAt
        endedAt
        group {
          title
        }
        totalAttended
        totalStudents
      }
    }
  }
`;
