import { ISidebarRoute } from "interfaces/Route";
import paths from "appConstants/RoutePaths";
import { ReactComponent as HomeIcon } from "../assets/home.svg";
import { ReactComponent as DashboardIcon } from "../assets/dashboard.svg";
import { ReactComponent as AdmissionIcon } from "../assets/admissions.svg";
import { ReactComponent as CourseIcon } from "../assets/course.svg";
import { ReactComponent as GroupsIcon } from "../assets/groups.svg";
import { ReactComponent as StudentsIcon } from "../assets/students.svg";
import { ReactComponent as EmployeeIcon } from "../assets/employees.svg";
import { ReactComponent as MoneyIcon } from "../assets/money.svg";
import { ReactComponent as DateIcon } from "../assets/date.svg";
import { ReactComponent as SettingsIcon } from "../assets/settings.svg";
import { ReactComponent as StudentAbsencesIcon } from "../assets/studentAbsencesIcon.svg";
import { ReactComponent as SessionsIcon } from "../assets/sessions.svg";
import { ReactComponent as SMSIcon } from "../assets/sms-icon.svg";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import ReceiptLongOutlinedIcon from "@mui/icons-material/ReceiptLongOutlined";

const {
  CENTER_INFO,
  CENTER_DASHBOARD,
  CENTER_ADMISSION,
  CENTER_COURSES,
  CENTER_GROUPS,
  CENTER_STUDENTS,
  CENTER_EMPLOYEES,
  CENTER_PAYMENTS,
  CENTER_SETTING,
  CENTER_SCHEDULE,
  CENTER_ADDITIONS,
  CENTER_EXPENSES,
  CENTER_STUDENT_ABSENCES,
  CENTER_SMS,
  CENTER_SESSIONS,
} = paths;

const SIDEBAR_ROUTES: ISidebarRoute[] = [
  {
    title: "home",
    path: CENTER_INFO,
    key: "info",
    section: "info",
    action: "view",
    type: "link",
    icon: <HomeIcon className="sidebar__link-icon" />,
  },
  {
    title: "dashboard",
    path: CENTER_DASHBOARD,
    key: "dashboard",
    section: "dashboard",
    action: "view",
    type: "link",
    icon: <DashboardIcon className="sidebar__link-icon" />,
  },
  {
    title: "admission",
    path: CENTER_ADMISSION,
    key: "admission",
    section: "admission",
    action: "view",
    type: "link",
    icon: <AdmissionIcon className="sidebar__link-icon" />,
  },
  {
    title: "courses",
    path: CENTER_COURSES,
    key: "courses",
    section: "courses",
    action: "view",
    type: "link",
    icon: <CourseIcon className="sidebar__link-icon" />,
  },
  {
    title: "groups",
    path: CENTER_GROUPS,
    key: "groups",
    section: "groups",
    action: "view",
    type: "link",
    icon: <GroupsIcon className="sidebar__link-icon" />,
  },
  {
    title: "students",
    path: CENTER_STUDENTS,
    key: "students",
    section: "students",
    action: "view",
    type: "link",
    icon: <StudentsIcon className="sidebar__link-icon" />,
  },
  {
    title: "employees",
    path: CENTER_EMPLOYEES,
    key: "employees",
    section: "employees",
    action: "view",
    type: "link",
    icon: <EmployeeIcon className="sidebar__link-icon" />,
  },
  {
    title: "payments",
    path: CENTER_PAYMENTS,
    key: "payments",
    section: "payments",
    action: "view",
    type: "link",
    icon: <MoneyIcon className="sidebar__link-icon" />,
  },
  {
    title: "expenses",
    path: CENTER_EXPENSES,
    key: "expenses",
    section: "expenses",
    action: "view",
    type: "link",
    icon: <ReceiptLongOutlinedIcon className="sidebar__link-icon" />,
  },
  {
    title: "schedule",
    path: CENTER_SCHEDULE,
    key: "schedule",
    section: "schedule",
    action: "view",
    type: "link",
    icon: <DateIcon className="sidebar__link-icon" />,
  },
  {
    title: "absences",
    path: CENTER_STUDENT_ABSENCES,
    key: "studentAbsences",
    section: "studentAbsences",
    extraSection: "groupAbsences",
    action: "view",
    type: "link",
    icon: <StudentAbsencesIcon className="sidebar__link-icon" />,
  },
  {
    title: "lessons",
    path: CENTER_SESSIONS,
    key: "sessionsPage",
    section: "sessions",
    action: "view",
    type: "link",
    icon: <SessionsIcon className="sidebar__link-icon" />,
  },
  {
    title: "additions",
    path: CENTER_ADDITIONS,
    key: "additions",
    section: "additions",
    action: "view",
    type: "link",
    icon: <AddBoxOutlinedIcon className="sidebar__link-icon" />,
  },
  {
    title: "sms",
    path: CENTER_SMS,
    key: "sms",
    section: "sms",
    action: "view",
    type: "link",
    icon: <SMSIcon className="sidebar__link-icon" />,
  },
  {
    title: "settings",
    path: CENTER_SETTING,
    key: "settings",
    section: "settings",
    action: "view",
    type: "link",
    icon: <SettingsIcon className="sidebar__link-icon" />,
  },
];

export default SIDEBAR_ROUTES;
