import { gql } from "@apollo/client";

const GET_ROOMS = gql`
  query getRooms($center: ID!) {
    getRooms(center: $center) {
      payload {
        _id
        title
        numberOfGroups
      }
    }
  }
`;

export const DELETE_ROOM = gql`
  mutation deleteRoom($center: ID!, $roomId: ID!) {
    deleteRoom(center: $center, roomId: $roomId) {
      payload {
        _id
      }
    }
  }
`;

export default GET_ROOMS;
