import SALARY_SHORTCUTS from "components/SalaryShortcuts/constants";
import { evaluate } from "mathjs";

function cleanExpression(expression: string) {
  let cleaned = expression.replace(/[^\d+\-*/().]/g, "");

  cleaned = cleaned.replace(/[\+\-*/]{2,}/g, (operator) => operator[0]);

  cleaned = cleaned.replace(/(\d)\s*([+\-*/])\s*(\d)/g, "$1$2$3");

  return cleaned;
}

export const formatFormulaToDisplay = (formula: string) => {
  let res = SALARY_SHORTCUTS.reduce((acc, { demoValue, key }) => {
    return acc.replace(new RegExp(key, "ig"), demoValue + "");
  }, formula || "");

  res = cleanExpression(res);

  let openParensCount = (res.match(/\(/g) || []).length;
  let closeParensCount = (res.match(/\)/g) || []).length;

  while (openParensCount > closeParensCount) {
    res = res.replace(/\(/, "");
    openParensCount--;
  }

  while (closeParensCount > openParensCount) {
    res = res.replace(/\)/, "");
    closeParensCount--;
  }

  if (/[\+\-\*/]$/.test(res)) {
    res = res.slice(0, -1);
  }

  return res;
};

export const calculateSalaryFormula = function (formula: string) {
  try {
    const res = evaluate(formula)?.toLocaleString("fi-Fi");

    return res;
  } catch (error) {
    return 0;
  }
};
