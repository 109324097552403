const paths = {
  SIGN_UP: "/sign-up",
  SIGN_IN: "/",
  PROFILE: "/profile",
  UPDATE_PROFILE: "/update-profile",
  CENTERS: "/centers",
  CENTERS_CREATE: "/centers/create",
  SCHEDULE: "/schedule",
  PAYMENTS: "/payments",
  NOTIFICATIONS: "/notifications",
  HOMEWORKS: "/homework",
  CENTER_INFO: "/center/",
  CENTER_DASHBOARD: "/center/dashboard",
  CENTER_ADMISSION: "/center/admission",
  CENTER_COURSES: "/center/courses",
  CENTER_CREATE_COURSE: "/center/create-course/",
  CENTER_COURSES_INFO: "/center/course/",
  CENTER_GROUPS: "/center/groups",
  CENTER_GROUP_CREATE: "/center/groups/create-group",
  CENTER_GROUPS_INFO: "/center/group/",
  CENTER_HOMEWORKS: "/center/homeworks",
  CENTER_HOMEWORKS_CREATE: "/center/create-homework",
  CENTER_HOMEWORKS_INFO: "/center/homework/",
  CENTER_STUDENTS: "/center/students",
  CENTER_EMPLOYEES: "/center/employees",
  CENTER_PAYMENTS: "/center/payments",
  CENTER_SCHEDULE: "/center/schedule",
  CENTER_SETTING: "/center/settings",
  CENTER_CRETE_PERMISSION: "/center/create-permission",
  CENTER_UPDATE_PERMISSION: "/center/update-permission",
  CENTER_ADDITIONS: "/center/additions",
  CENTER_INFO_UPDATE: "/center/info-update",
  CENTER_UPDATE_COURSE: "/center/update-course/",
  CENTER_UPDATE_GROUP: "/center/groups/update-group",
  CENTER_UPDATE_HOMEWORK: "/center/update-homework",
  CENTER_CREATE_STUDENT: "/center/create-student",
  CENTER_UPDATE_STUDENT: "/center/update-student",
  RESET_PHONE_VALIDATION: "/reset/phone-validation",
  RESET_VERIFY_CODE: "/reset/verify-code",
  RESET_PASSWORD: "/reset/reset-password",
  CENTER_EXPENSES: "/center/expenses",
  CENTER_STUDENT_INFO: "/center/student/",
  CENTER_EMPLOYEE_INFO: "/center/employee/",
  CENTER_STUDENT_ABSENCES: "/center/student-absences/",
  CENTER_ROADMAP_INFO: "/center/additions/roadmap/",
  CENTER_SUBJECT_INFO: "/center/additions/roadmap/subject/",
  CENTER_SMS: "/center/sms",
  CENTER_SESSIONS: "/center/sessions",
};

export default paths;
