import { gql } from "@apollo/client";

export const RESET_PASSWORD = gql`
  mutation resetPassword(
    $newPassword: String!
    $token: String!
    $phone: String!
  ) {
    resetPassword(newPassword: $newPassword, token: $token, phone: $phone)
  }
`;
