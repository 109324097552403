import StyleLoader from "./Style";
import { CircularProgress } from "@mui/material";

type Props = {
  isInner?: boolean;
};

function Loader({ isInner }: Props) {
  return (
    <StyleLoader style={{ position: isInner ? "absolute" : "fixed" }}>
      <CircularProgress />
    </StyleLoader>
  );
}

export default Loader;
