import t from "translate";
import moment from "moment";
import { GridColDef } from "@mui/x-data-grid";
import { Avatar, Box, Typography } from "@mui/material";

export const SESSIONS_PAGE_COLUMNS: GridColDef[] = [
  {
    flex: 1,
    minWidth: 200,
    field: "theme",
    headerName: t("theme"),
    renderCell({ row }) {
      return row?.theme || row?.topic?.title;
    },
  },
  {
    flex: 1,
    minWidth: 200,
    field: "group",
    headerName: t("group"),
    renderCell({ row }) {
      return row?.group?.title;
    },
  },
  {
    flex: 1,
    minWidth: 300,
    field: "teacher",
    headerName: t("teacher"),
    renderCell({ row }) {
      return (
        <Box display="flex" alignItems="center" gap={1}>
          <Avatar
            src={row?.teacher?.image}
            sx={{ fontSize: 12, width: 32, height: 32 }}
          >
            {row?.teacher?.name?.[0] || "A"}
          </Avatar>
          <Typography variant="subtitle2">{row?.teacher?.name}</Typography>
        </Box>
      );
    },
  },
  {
    flex: 1,
    minWidth: 150,
    field: "time",
    headerName: t("duration"),
    renderCell({ row }) {
      return (
        <Typography>
          {moment(row?.startedAt).format("HH:mm")} -
          {moment(row?.endedAt).format("HH:mm")}
        </Typography>
      );
    },
  },
  {
    flex: 1,
    minWidth: 150,
    field: "attendance",
    headerName: t("attendance"),
    align: "center",
    headerAlign: "center",
    renderCell({ row }) {
      return row?.totalAttended + "/" + row?.totalStudents;
    },
  },
];
