import React, { useEffect, useRef, useState } from "react";
import { Box, Button, Link, Typography } from "@mui/material";
import AuthWrapper from "UI/AuthWrapper";
import t from "translate";
import VerificationInput from "react-verification-input";
import StyledVerifyCode from "./VerifyCode.style";
import { resendDelay } from "./constants";
import { useAppSelector } from "hooks/redux";
import { CONFIRM_RESET_PASSWORD_CODE } from "./api";
import Loader from "components/Loader";
import { useMutation } from "@apollo/client";
import { RESET_PASSWORD_PHONE_CONFIRM } from "../PhoneValidation/api";
import { useNavigate } from "react-router-dom";
import paths from "appConstants/RoutePaths";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setResetPasswordData } from "store/reducer/AuthSlice";
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";

function VerifyCode() {
  const count = useRef<any>(null);
  const [code, setCode] = useState("");
  const [counter, setCounter] = useState(resendDelay);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const resetData = useAppSelector((state) => state.auth.resetPasswordData);

  const [
    resetPasswordPhoneConfirm,
    { data: resendData, loading: resendLoading },
  ] = useMutation(RESET_PASSWORD_PHONE_CONFIRM);
  const [confirmResetPasswordCode, { data, loading }] = useMutation(
    CONFIRM_RESET_PASSWORD_CODE
  );

  const handleResend = () => {
    if (resetData?.phone) {
      resetPasswordPhoneConfirm({
        variables: {
          phone: resetData?.phone,
        },
      });
      setCounter(resendDelay);
      return;
    }
    navigate(paths.RESET_PHONE_VALIDATION);
    toast.warning(
      t(
        "You haven't entered your phone number yet! So enter your phone number first."
      )
    );
  };

  const handleVerify = () => {
    if (resetData?.phone) {
      confirmResetPasswordCode({
        variables: {
          token: resetData?.token,
          code,
        },
      });
    }
  };

  useEffect(() => {
    count.current = setInterval(() => {
      if (counter > 0) {
        setCounter((prev) => prev - 1);
      }
    }, 1000);
    return () => {
      clearInterval(count?.current);
    };
  }, [counter]);

  useEffect(() => {
    if (!resendLoading && resendData) {
      const token = resendData?.resetPasswordPhoneConfirm?.payload?.token;
      dispatch(
        setResetPasswordData({
          token,
          phone: resetData?.phone,
        })
      );
    }
  }, [resendData, resendLoading]);

  useEffect(() => {
    if (!loading && data) {
      navigate(paths.RESET_PASSWORD);
    }
  }, [data, loading]);

  const formattedPhone =
    resetData?.phone.slice(0, resetData?.phone.length - 4) + "****";

  return (
    <AuthWrapper
      title="verify code"
      subTitle={`${t(
        "Enter the 5-digit verification code sent to your phone number"
      )}: ${formattedPhone}`}
      supLinkText="sign in"
      supLinkPath={paths.SIGN_IN}
      supLinkIcon={<ArrowBackIosRoundedIcon fontSize="small" />}
    >
      {(loading || resendLoading) && <Loader />}
      <StyledVerifyCode>
        <Box gap={3} display="flex" flexDirection="column">
          <VerificationInput
            length={5}
            placeholder="_"
            onChange={setCode}
            classNames={{
              container: "verify__container",
              character: "verify__character",
              characterInactive: "verify__character--inactive",
              characterSelected: "verify__character--selected",
            }}
          />
          <Box gap={1} display="flex" alignItems="center">
            <Typography color="grey.600" variant="body1">
              {t("didn't get a code?")}
            </Typography>
            {counter > 0 ? (
              <Typography variant="body1">
                00:{counter?.toString().length >= 2 ? counter : "0" + counter}
              </Typography>
            ) : (
              <Link underline="none" variant="body1" onClick={handleResend}>
                {t("resend")}
              </Link>
            )}
          </Box>
          <Button
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            onClick={handleVerify}
            disabled={code.length < 5}
          >
            {t("verify")}
          </Button>
        </Box>
      </StyledVerifyCode>
    </AuthWrapper>
  );
}

export default VerifyCode;
