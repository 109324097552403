import { gql } from "@apollo/client";

export const CREATE_ROADMAP = gql`
  mutation createRoadmap($center: ID!, $title: String!, $description: String!) {
    createRoadmap(center: $center, title: $title, description: $description) {
      payload {
        _id
      }
    }
  }
`;
