import { gql } from "@apollo/client";

export const UPDATE_ROOM = gql`
  mutation updateRoom($center: ID!, $title: String!, $roomId: ID!) {
    updateRoom(center: $center, title: $title, roomId: $roomId) {
      payload {
        _id
      }
    }
  }
`;
