import { Box, Tab, Tabs, Typography } from "@mui/material";
import { usePermission } from "hooks/permission";
import { useAppSelector } from "hooks/redux";
import { ITabs } from "interfaces/Route";
import React, { useEffect, useState } from "react";
import t from "translate";
import { ROADMAP_TABS } from "./constants";
import { useQuery } from "@apollo/client";
import GET_ROADMAP from "./api";
import { useParams } from "react-router-dom";

function RoadmapInfo() {
  const [selectedTab, setSelectedTab] = useState(ROADMAP_TABS[0]?.key);
  const [allowedTabs, setAllowedTabs] = useState<ITabs[]>([]);

  const { id } = useParams();
  const centerId = useAppSelector((state) => state.center.center?._id);

  const { data, refetch } = useQuery(GET_ROADMAP, {
    variables: {
      center: centerId,
      roadmapId: id,
    },
  });

  const { PermissionRoutes } = usePermission();
  const permission = useAppSelector((state) => state.center.permission);

  const ActiveTabElement = ROADMAP_TABS.find(
    (tab) => tab.key === selectedTab
  )?.Element;

  const roadmap = data?.getRoadmapById?.payload || {};

  useEffect(() => {
    if (!allowedTabs.length) {
      const tabs = PermissionRoutes(ROADMAP_TABS);
      setAllowedTabs(tabs);
      setSelectedTab(tabs[0]?.key);
    }
  }, [permission]);

  return (
    <>
      <Box display="flex" flexDirection="column" gap={1}>
        <Typography variant="h4" mb={2}>
          {roadmap?.title || "Unknown"}
        </Typography>
      </Box>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={selectedTab}
          variant="scrollable"
          onChange={(_, activeTab) => setSelectedTab(activeTab)}
        >
          {allowedTabs.map(({ key, title }) => {
            return <Tab label={t(title)} key={key} value={key} />;
          })}
        </Tabs>
      </Box>
      <Box paddingY={4}>
        {ActiveTabElement && (
          <ActiveTabElement
            roadmapId={roadmap?._id || id}
            center={centerId}
            title={roadmap.title}
            description={roadmap.description}
            refetch={refetch}
          />
        )}
      </Box>
    </>
  );
}

export default RoadmapInfo;
