import React from "react";
import { Box, CircularProgress } from "@mui/material";
import { pxToRem } from "utils";

type Props = {
  disableMargin?: boolean;
};

function InnerLoader({ disableMargin }: Props) {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        py: pxToRem(20),
        mt: disableMargin ? 0 : pxToRem(30),
      }}
    >
      <CircularProgress />
    </Box>
  );
}

export default InnerLoader;
