import { ITabs } from "interfaces/Route";
import Topics from "../tabs/Topics";
import Actions from "../tabs/Actions";

export const SUBJECT_TABS: ITabs[] = [
  {
    title: "Topics",
    Element: Topics,
    key: "topics",
    section: "topics",
    action: "view",
  },
  {
    title: "actions",
    Element: Actions,
    key: "subject",
    section: "subjects",
    action: "edit",
  },
];
