import React, {
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import {
  Box,
  colors,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import t from "translate";
import { toast } from "react-toastify";
import { useMutation } from "@apollo/client";
import { usePermission } from "hooks/permission";

import Loader from "components/Loader";
import UpdateRoomModal from "../UpdateModal";
import ConfirmModal from "components/ConfirmModal";

import { DELETE_DISCOUNT } from "../../api";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";

type Props = {
  refetch: any;
  discountId: string;
  center: string;
  title: string;
  percentage: number;
};

function DiscountActionButton({
  refetch,
  center,
  discountId,
  title,
  percentage,
}: Props) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isDeleteModalVisible, setIsDeleteModalVisible] =
    useState<boolean>(false);
  const [isUpdateModalVisible, setIsUpdateModalVisible] =
    useState<boolean>(false);

  const { isActionAllowed } = usePermission();

  const [deleteDiscount, { data, loading }] = useMutation(DELETE_DISCOUNT);

  const handleMoreClick = useCallback((event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleMenuClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const toggleDeleteModalVisible = useCallback(
    () => setIsDeleteModalVisible((prev) => !prev),
    []
  );
  const toggleUpdateModalVisible = useCallback(
    () => setIsUpdateModalVisible((prev) => !prev),
    []
  );

  const isDeleteAllowed = useMemo(
    () =>
      isActionAllowed({
        section: "discounts",
        action: "delete",
      }),

    [isActionAllowed]
  );
  const isEditAllowed = useMemo(
    () =>
      isActionAllowed({
        section: "discounts",
        action: "edit",
      }),
    [isActionAllowed]
  );

  const handleDelete = useCallback(() => {
    deleteDiscount({
      variables: {
        discountId,
        center,
      },
    });
  }, [discountId, center, deleteDiscount]);

  useEffect(() => {
    if (!loading && data) {
      toast.success(t("Discount deleted successfully!"));
      refetch();
      toggleDeleteModalVisible();
    }
  }, [data, loading]);

  if (!isDeleteAllowed && !isEditAllowed) {
    return <></>;
  }

  return (
    <>
      {loading && <Loader />}
      <IconButton size="small" onClick={handleMoreClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        open={!!anchorEl}
        id="basic-menu"
        anchorEl={anchorEl}
        onClose={handleMenuClose}
        MenuListProps={{
          "aria-labelledby": "student-action-menu",
        }}
        transformOrigin={{ horizontal: "center", vertical: "top" }}
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
      >
        {isEditAllowed && (
          <Box>
            <MenuItem sx={{ gap: 1 }} onClick={toggleUpdateModalVisible}>
              <EditOutlinedIcon color="warning" />
              <Typography color={colors.orange[800]}>{t("edit")}</Typography>
            </MenuItem>
            <Divider />
          </Box>
        )}
        {isDeleteAllowed && (
          <MenuItem sx={{ gap: 1 }} onClick={toggleDeleteModalVisible}>
            <DeleteOutlinedIcon color="error" />
            <Typography color={colors.red[800]}>{t("delete")}</Typography>
          </MenuItem>
        )}
      </Menu>
      <ConfirmModal
        onConfirm={handleDelete}
        open={isDeleteModalVisible}
        onClose={toggleDeleteModalVisible}
      />
      <UpdateRoomModal
        onClose={toggleUpdateModalVisible}
        refetch={refetch}
        open={isUpdateModalVisible}
        title={title}
        discountId={discountId}
        percentage={percentage}
      />
    </>
  );
}

export default DiscountActionButton;
