import { gql } from "@apollo/client";

export const GET_STUDENTS_AND_APPLY_REQUESTS_FOR_SEARCH = gql`
  query getStudentsAndApplyRequestsForSearch($text: String, $center: ID!) {
    getStudentsAndApplyRequestsForSearch(text: $text, center: $center) {
      payload {
        students {
          _id
          name
          phone
          extraPhone
        }
        applyRequests {
          _id
          name
          phone
          extraPhone
          status
          createdAt
        }
      }
    }
  }
`;
