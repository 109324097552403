import React, { useCallback, useMemo, useState } from "react";

import { Button, Collapse, List, ListItem, ListItemText } from "@mui/material";
import t from "translate";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import SALARY_SHORTCUTS from "./constants";

function SalaryShortcutsList() {
  const [isShortcutsOpen, setIsShortcutsOpen] = useState(false);

  const toggleShortcutsOpen = useCallback(() => {
    setIsShortcutsOpen((prev) => !prev);
  }, []);

  const shortcutsList = useMemo(
    () => (
      <List>
        {SALARY_SHORTCUTS.map(({ key, description }) => (
          <ListItem key={key} alignItems="flex-start">
            <ListItemText primary={key} secondary={t(description)} />
          </ListItem>
        ))}
      </List>
    ),
    []
  );

  return (
    <>
      <Button onClick={toggleShortcutsOpen} startIcon={<InfoOutlinedIcon />}>
        {t("Show shortcuts")}
      </Button>
      <Collapse in={isShortcutsOpen}>{shortcutsList}</Collapse>
    </>
  );
}

export default SalaryShortcutsList;
