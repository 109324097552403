import React, { useState } from "react";

import moment, { Moment } from "moment";
import { useQuery } from "@apollo/client";
import { DataGrid } from "@mui/x-data-grid";
import { useAppSelector } from "hooks/redux";
import { DatePicker } from "@mui/x-date-pickers";

import NoData from "UI/NoData";
import PageHeader from "UI/PageHeader";
import { GET_ALL_FINISHED_SESSIONS } from "./api";
import { SESSIONS_PAGE_COLUMNS } from "./constants";

function Sessions() {
  const [date, setDate] = useState<Moment | null>(moment());
  const centerId = useAppSelector((store) => store.center.center?._id);

  const { data, loading } = useQuery(GET_ALL_FINISHED_SESSIONS, {
    variables: {
      center: centerId,
      date: date,
    },
  });

  const sessions = data?.getAllSessionsByDate?.payload || [];
  return (
    <>
      <PageHeader title="lessons">
        <DatePicker
          format="DD/MM/YYYY"
          defaultValue={moment()}
          disableFuture
          onAccept={setDate}
        />
      </PageHeader>
      <div
        style={{
          height: sessions.length === 0 ? 400 : "auto",
          width: "100%",
        }}
      >
        <DataGrid
          loading={loading}
          rows={sessions}
          columns={SESSIONS_PAGE_COLUMNS}
          getRowId={(row) => row?._id}
          hideFooter
          showCellVerticalBorder
          showColumnVerticalBorder
          slots={{ noResultsOverlay: NoData, noRowsOverlay: NoData }}
        />
      </div>
    </>
  );
}

export default Sessions;
