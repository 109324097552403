import { lazy } from "react";

import { IRoute } from "interfaces/Route";
import paths from "appConstants/RoutePaths";

import SignIn from "pages/Auth/SignIn";
import SignUp from "pages/Auth/SignUp";
import ResetPasswordPhone from "pages/Auth/ResetPassword/PhoneValidation";
import VerifyCode from "pages/Auth/ResetPassword/VerifyCode";
import ResetPassword from "pages/Auth/ResetPassword/Reset";
import Additions from "pages/Center/Additions";
import RoadmapInfo from "pages/Center/Additions/tabs/Roadmap/pages/Info";
import SubjectInfo from "pages/Center/Additions/tabs/Roadmap/pages/SubjectInfo";
import Sessions from "pages/Center/Sessions";

const UserProfile = lazy(() => import("pages/User/Profile"));
const UpdateProfile = lazy(() => import("pages/User/Profile/pages/Update"));

const Centers = lazy(() => import("pages/User/Centers"));
const CreateCenter = lazy(() => import("pages/User/Centers/pages/Create"));

const UserPayments = lazy(() => import("pages/User/Payments"));

// const Homeworks = lazy(() => import("pages/User/Homeworks"));

const UserSchedule = lazy(() => import("pages/User/Schedule"));

const CenterInfo = lazy(() => import("pages/Center/Info"));
const UpdateCenterInfo = lazy(() => import("pages/Center/Info/pages/Update"));

const Dashboard = lazy(() => import("pages/Center/Dashboard"));
const Admission = lazy(() => import("pages/Center/Admission"));

const Courses = lazy(() => import("pages/Center/Courses"));
const CourseInfo = lazy(() => import("pages/Center/Courses/pages/Info"));
const CreateCourse = lazy(() => import("pages/Center/Courses/pages/Create"));
const UpdateCourse = lazy(() => import("pages/Center/Courses/pages/Update"));

const Groups = lazy(() => import("pages/Center/Groups"));
const GroupInfo = lazy(() => import("pages/Center/Groups/pages/Info"));
const CreateGroup = lazy(() => import("pages/Center/Groups/pages/Create"));
const UpdateGroup = lazy(() => import("pages/Center/Groups/pages/Update"));

const HomeworkInfo = lazy(
  () => import("pages/Center/Groups/pages/Info/tabs/Homeworks/pages/Info")
);
const CreateHomework = lazy(
  () => import("pages/Center/Groups/pages/Info/tabs/Homeworks/pages/Create")
);
const UpdateHomework = lazy(
  () => import("pages/Center/Groups/pages/Info/tabs/Homeworks/pages/Update")
);

const Students = lazy(() => import("pages/Center/Students"));
const StudentInfo = lazy(() => import("pages/Center/Students/pages/Info"));
const CreateStudent = lazy(() => import("pages/Center/Students/pages/Create"));
const UpdateStudent = lazy(() => import("pages/Center/Students/pages/Update"));

const Employees = lazy(() => import("pages/Center/Employees"));
const EmployeeInfo = lazy(() => import("pages/Center/Employees/pages/Info"));

const Payments = lazy(() => import("pages/Center/Payments"));

const Expenses = lazy(() => import("pages/Center/Expenses"));
const StudentAbsences = lazy(() => import("pages/Center/Absences"));

const Schedule = lazy(() => import("pages/Center/Schedule"));
const Settings = lazy(() => import("pages/Center/Settings"));
const CreatePermission = lazy(
  () => import("pages/Center/Settings/pages/CreatePermission")
);
const UpdatePermission = lazy(
  () => import("pages/Center/Settings/pages/UpdatePermission")
);
const SMS = lazy(() => import("pages/Center/SMS"));

const {
  SIGN_IN,
  SIGN_UP,
  CENTERS,
  SCHEDULE,
  CENTERS_CREATE,
  CENTER_ADMISSION,
  CENTER_COURSES,
  CENTER_CREATE_COURSE,
  CENTER_COURSES_INFO,
  CENTER_DASHBOARD,
  CENTER_EMPLOYEES,
  CENTER_GROUPS,
  CENTER_GROUPS_INFO,
  CENTER_GROUP_CREATE,
  CENTER_HOMEWORKS_CREATE,
  CENTER_HOMEWORKS_INFO,
  CENTER_INFO,
  CENTER_PAYMENTS,
  CENTER_SETTING,
  CENTER_STUDENTS,
  PAYMENTS,
  PROFILE,
  CENTER_SCHEDULE,
  CENTER_CRETE_PERMISSION,
  CENTER_UPDATE_PERMISSION,
  CENTER_INFO_UPDATE,
  CENTER_UPDATE_COURSE,
  CENTER_UPDATE_GROUP,
  CENTER_UPDATE_HOMEWORK,
  CENTER_CREATE_STUDENT,
  CENTER_UPDATE_STUDENT,
  UPDATE_PROFILE,
  RESET_PASSWORD,
  RESET_PHONE_VALIDATION,
  RESET_VERIFY_CODE,
  CENTER_EXPENSES,
  CENTER_STUDENT_INFO,
  CENTER_EMPLOYEE_INFO,
  CENTER_STUDENT_ABSENCES,
  CENTER_ADDITIONS,
  CENTER_ROADMAP_INFO,
  CENTER_SUBJECT_INFO,
  CENTER_SMS,
  CENTER_SESSIONS,
} = paths;

export const publicRoutes: IRoute[] = [
  {
    path: SIGN_IN,
    component: <SignIn />,
    key: "signIn",
  },
  {
    path: SIGN_UP,
    component: <SignUp />,
    key: "signUp",
  },
  {
    path: RESET_PHONE_VALIDATION,
    component: <ResetPasswordPhone />,
    key: "phoneValidation",
  },
  {
    path: RESET_VERIFY_CODE,
    component: <VerifyCode />,
    key: "verifyCode",
  },
  {
    path: RESET_PASSWORD,
    component: <ResetPassword />,
    key: "resetPassword",
  },
];

export const privateRoutes: IRoute[] = [
  {
    path: "/",
    component: <UserProfile />,
    key: "user",
  },
  {
    path: PROFILE,
    component: <UserProfile />,
    key: "userProfile",
  },
  {
    path: CENTERS,
    component: <Centers />,
    key: "centers",
  },
  {
    path: SCHEDULE,
    component: <UserSchedule />,
    key: "schedule",
  },
  {
    path: CENTERS_CREATE,
    component: <CreateCenter />,
    key: "createCenter",
  },
  {
    path: PAYMENTS,
    component: <UserPayments />,
    key: "payments",
  },

  // {
  //   path: HOMEWORKS,
  //   component: <Homeworks />,
  //   key: "homework",
  // },
  {
    path: CENTER_INFO,
    component: <CenterInfo />,
    key: "centerInfo",
  },
  {
    path: UPDATE_PROFILE,
    component: <UpdateProfile />,
    key: "updateProfile",
  },
];

export const centerRoutes: IRoute[] = [
  {
    path: CENTER_DASHBOARD,
    component: <Dashboard />,
    key: "dashboard",
    section: "dashboard",
    action: "view",
  },
  {
    path: CENTER_ADMISSION,
    component: <Admission />,
    key: "admission",
    section: "admission",
    action: "view",
  },
  {
    path: CENTER_COURSES,
    component: <Courses />,
    key: "courses",
    section: "courses",
    action: "view",
  },
  {
    path: CENTER_CREATE_COURSE,
    component: <CreateCourse />,
    key: "createCourse",
    section: "courses",
    action: "create",
  },
  {
    path: CENTER_COURSES_INFO,
    component: <CourseInfo />,
    key: "courseInfo",
    section: "courses",
    action: "view",
  },
  {
    path: CENTER_GROUPS,
    component: <Groups />,
    key: "groups",
    section: "groups",
    action: "view",
  },
  {
    path: CENTER_GROUP_CREATE,
    component: <CreateGroup />,
    key: "createGroup",
    section: "groups",
    action: "create",
  },
  {
    path: CENTER_GROUPS_INFO,
    component: <GroupInfo />,
    key: "groupInfo",
    section: "groups",
    action: "viewInfo",
  },
  // {
  //   path: CENTER_HOMEWORKS,
  //   component: <Homeworks />,
  //   key: "centerHomeworks",
  //   section: "groups",
  //   action: "viewHomework",
  // },
  {
    path: CENTER_HOMEWORKS_CREATE,
    component: <CreateHomework />,
    key: "createHomework",
    section: "groups",
    action: "createHomework",
  },
  {
    path: CENTER_HOMEWORKS_INFO,
    component: <HomeworkInfo />,
    key: "homeworkInfo",
    section: "groups",
    action: "viewHomework",
  },
  {
    path: CENTER_STUDENTS,
    component: <Students />,
    key: "students",
    section: "students",
    action: "view",
  },
  {
    path: CENTER_EMPLOYEES,
    component: <Employees />,
    key: "employees",
    section: "employees",
    action: "view",
  },
  {
    path: CENTER_PAYMENTS,
    component: <Payments />,
    key: "centerPayment",
    section: "payments",
    action: "view",
  },
  {
    path: CENTER_SCHEDULE,
    component: <Schedule />,
    key: "schedule",
    section: "schedule",
    action: "view",
  },
  {
    path: CENTER_SETTING,
    component: <Settings />,
    key: "settings",
    section: "settings",
    action: "view",
  },
  {
    path: CENTER_CRETE_PERMISSION,
    component: <CreatePermission />,
    key: "createPermission",
    section: "settings",
    action: "view",
  },
  {
    path: CENTER_UPDATE_PERMISSION,
    component: <UpdatePermission />,
    key: "createPermission",
    section: "settings",
    action: "view",
  },
  {
    path: CENTER_INFO_UPDATE,
    component: <UpdateCenterInfo />,
    key: "salaries",
    section: "salaries",
    action: "view",
  },
  {
    path: CENTER_UPDATE_COURSE,
    component: <UpdateCourse />,
    key: "updateCourse",
    section: "courses",
    action: "edit",
  },
  {
    path: CENTER_UPDATE_GROUP,
    component: <UpdateGroup />,
    key: "updateGroup",
    section: "groups",
    action: "viewAction",
  },
  {
    path: CENTER_UPDATE_HOMEWORK,
    component: <UpdateHomework />,
    key: "updateHomework",
    section: "groups",
    action: "editHomework",
  },
  {
    path: CENTER_CREATE_STUDENT,
    component: <CreateStudent />,
    key: "createStudent",
    section: "students",
    action: "add",
  },
  {
    path: CENTER_UPDATE_STUDENT,
    component: <UpdateStudent />,
    key: "updateStudent",
    section: "students",
    action: "view",
  },
  {
    path: CENTER_EXPENSES,
    component: <Expenses />,
    key: "expenses",
    section: "payments",
    action: "view",
  },
  {
    path: CENTER_STUDENT_INFO,
    component: <StudentInfo />,
    key: "studentInfo",
    section: "students",
    action: "viewInfo",
  },
  {
    path: CENTER_EMPLOYEE_INFO,
    component: <EmployeeInfo />,
    key: "employeeInfo",
    section: "employees",
    action: "view",
  },
  {
    path: CENTER_STUDENT_ABSENCES,
    component: <StudentAbsences />,
    key: "studentAbsences",
    section: "studentAbsences",
    extraSection: "groupAbsences",
    action: "view",
  },
  {
    path: CENTER_ADDITIONS,
    component: <Additions />,
    key: "additions",
    section: "additions",
    action: "view",
  },
  {
    path: CENTER_ROADMAP_INFO + ":id",
    component: <RoadmapInfo />,
    key: "roadmapInfo",
    section: "subjects",
    action: "view",
  },
  {
    path: CENTER_SUBJECT_INFO + ":id",
    component: <SubjectInfo />,
    key: "subjectInfo",
    section: "topics",
    action: "view",
  },
  {
    path: CENTER_SMS,
    component: <SMS />,
    key: "sms",
    section: "sms",
    action: "view",
  },
  {
    path: CENTER_SESSIONS,
    component: <Sessions />,
    key: "sessionsPage",
    section: "sessions",
    action: "view",
  },
];
