import { useMutation } from "@apollo/client";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputAdornment,
  TextField,
} from "@mui/material";
import Loader from "components/Loader";
import { CREATE_SALARY } from "./api";
import { useAppSelector } from "hooks/redux";
import { useEffect, useState } from "react";
import { Controller, FieldValues, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import t from "translate";
import SalaryFormulaInput from "components/SalaryFormulaInput";
import SalaryShortcutsList from "components/SalaryShortcuts";

import SalaryTypeCard, { SalaryVariantsType } from "../SalaryTypeCard";
import NumberInput from "UI/NumberInput";

interface Props {
  open: boolean;
  toggleModalVisible: VoidFunction;
  refetch: any;
}

function CreateSalary({ open, toggleModalVisible, refetch }: Props) {
  const [salaryType, setSalaryType] = useState<SalaryVariantsType>("fixed");

  const centerId = useAppSelector((store) => store.center.center?._id);
  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm();

  const [createSalary, { data, loading }] = useMutation(CREATE_SALARY);

  const handleCancel = () => {
    reset({});
    toggleModalVisible();
  };

  const handleFinish = ({ title, amount, formula }: FieldValues) => {
    let normalizedAmount = Number(amount);
    if (salaryType === "percent") {
      normalizedAmount =
        Number(amount) > 100 ? 100 : Number(amount) < 0 ? 10 : Number(amount);
    }

    createSalary({
      variables: {
        center: centerId,
        data: {
          title,
          amount: normalizedAmount,
          formula,
          type: salaryType,
        },
      },
    });
  };

  useEffect(() => {
    if (!loading && data) {
      handleCancel();
      refetch();
      toast.success(t("salary created successfully"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, loading]);

  return (
    <>
      {loading && <Loader />}
      <Dialog open={open} onClose={handleCancel}>
        <DialogTitle>{t("create salary")}</DialogTitle>
        <DialogContent>
          <Box gap={3} display="flex" flexDirection="column">
            <Box gap={2} display="flex">
              <SalaryTypeCard
                onSelect={setSalaryType}
                selectedType={salaryType}
                type="fixed"
              />
              <SalaryTypeCard
                onSelect={setSalaryType}
                selectedType={salaryType}
                type="percent"
              />
              {/* <SalaryTypeCard
                onSelect={setSalaryType}
                selectedType={salaryType}
                type="formula"
              /> */}
            </Box>
            <Box gap={2} display="flex" component="form" flexDirection="column">
              <Controller
                name="title"
                rules={{
                  required: true,
                }}
                control={control}
                render={({ field }) => {
                  return (
                    <FormControl fullWidth>
                      <TextField
                        label={t("title")}
                        {...field}
                        error={!!errors[field.name]}
                        helperText={
                          !!errors[field.name] && t("Please input title!")
                        }
                      />
                    </FormControl>
                  );
                }}
              />

              {salaryType !== "formula" && (
                <Controller
                  name="amount"
                  rules={{
                    required: true,
                  }}
                  control={control}
                  render={({ field }) => {
                    return (
                      <FormControl fullWidth>
                        <NumberInput
                          field={field}
                          label="amount"
                          errors={errors}
                          helperText="Please input amount!"
                          InputProps={{
                            endAdornment:
                              salaryType === "percent" ? (
                                <InputAdornment position="end">
                                  %
                                </InputAdornment>
                              ) : (
                                <InputAdornment position="end">
                                  sum
                                </InputAdornment>
                              ),
                            inputProps:
                              salaryType === "percent"
                                ? { max: 100, min: 10 }
                                : {},
                          }}
                        />
                      </FormControl>
                    );
                  }}
                />
              )}

              {salaryType === "formula" && (
                <SalaryFormulaInput
                  control={control}
                  errors={errors}
                  name="formula"
                  title="Enter salary formula"
                />
              )}
            </Box>
          </Box>
          {salaryType === "formula" && <SalaryShortcutsList />}
        </DialogContent>
        <DialogActions>
          <Button
            fullWidth
            color="inherit"
            variant="contained"
            onClick={handleCancel}
          >
            {t("cancel")}
          </Button>
          <Button
            fullWidth
            variant="contained"
            onClick={handleSubmit(handleFinish)}
          >
            {t("create")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default CreateSalary;
