import { gql } from "@apollo/client";

export const GET_ROADMAPS = gql`
  query getPaginatedRoadmaps($center: ID!, $size: Int, $page: Int) {
    getPaginatedRoadmaps(center: $center, size: $size, page: $page) {
      payload {
        _id
        title
        description
        subjectsAmount
      }
      totalPages
    }
  }
`;
