import React, { useState } from "react";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import {
  ControllerRenderProps,
  FieldErrors,
  FieldValues,
} from "react-hook-form";
import t from "translate";

type Props = {
  label?: string;
  helperText?: string;
  errors: FieldErrors<FieldValues>;
  field: ControllerRenderProps<FieldValues, any>;
};

function PasswordInput({ field, errors, label, helperText }: Props) {
  const [isShow, setIsShow] = useState(false);

  const handleShowPassword = () => setIsShow((prev) => !prev);

  return (
    <TextField
      label={t(label ? label : "password")}
      {...field}
      type={isShow ? "text" : "password"}
      value={field.value}
      InputProps={{
        endAdornment: isShow ? (
          <InputAdornment position="end">
            <IconButton size="medium">
              <VisibilityOffOutlinedIcon
                fontSize="small"
                onClick={handleShowPassword}
              />
            </IconButton>
          </InputAdornment>
        ) : (
          <InputAdornment position="end">
            <IconButton size="medium">
              <VisibilityOutlinedIcon
                fontSize="small"
                onClick={handleShowPassword}
              />
            </IconButton>
          </InputAdornment>
        ),
      }}
      error={!!errors[field.name]}
      helperText={
        !!errors[field.name] &&
        t(helperText ? helperText : "Please input password!")
      }
    />
  );
}

export default PasswordInput;
