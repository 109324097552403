import {
  ClickAwayListener,
  FormControl,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import SALARY_SHORTCUTS from "components/SalaryShortcuts/constants";
import React, { RefObject, useCallback, useRef, useState } from "react";
import { Control, Controller, FieldErrors, FieldValues } from "react-hook-form";
import t from "translate";
import { calculateSalaryFormula, formatFormulaToDisplay } from "./helpers";

type Props = {
  control: Control<FieldValues, any>;
  errors: FieldErrors<FieldValues>;
  name: string;
  title: string;
  margin?: "dense" | "normal" | "none" | undefined;
};

function SalaryFormulaInput({ control, errors, name, title, margin }: Props) {
  const [isHelperOpen, setIsHelperOpen] = useState(false);
  const [cursorPosition, setCursorPosition] = useState(-1);

  const inputRef: RefObject<HTMLDivElement> = useRef(null);

  const toggleHelperOpen = useCallback(() => {
    setIsHelperOpen((prev) => !prev);
  }, []);

  return (
    <div ref={inputRef}>
      <Controller
        name={name}
        control={control}
        rules={{ required: true }}
        render={({ field }) => {
          const onInput = (
            e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
          ) => {
            field.onChange(e);

            const value = e.target.value;
            const cursorPosition = e.target.selectionStart ?? value.length;
            const lastSpaceIndex = value.lastIndexOf(" ", cursorPosition);
            const startIndex = lastSpaceIndex === -1 ? 0 : lastSpaceIndex;
            const lastWord = value.slice(startIndex).toLowerCase().trim();

            if (lastWord.startsWith("m") && !isHelperOpen) {
              setCursorPosition(cursorPosition);
              toggleHelperOpen();
            }
          };

          const onItemSelect = (key: string) => {
            const value = field?.value as string;
            const searchSpaceFrom =
              cursorPosition === -1 ? value.length : cursorPosition;

            const beforeSpaceIndex = value.lastIndexOf(" ", searchSpaceFrom);
            const afterSpaceIndex = value.indexOf(" ", searchSpaceFrom);
            const valueBeforeKey = value.slice(
              0,
              beforeSpaceIndex === -1 ? 0 : beforeSpaceIndex
            );
            const valueAfterKey =
              afterSpaceIndex !== -1 ? value.slice(afterSpaceIndex) : "";
            const valueWithAddShortcut = `${valueBeforeKey} ${key}${valueAfterKey}`;
            field.onChange({
              target: { value: valueWithAddShortcut.trim() },
            });
            toggleHelperOpen();
          };
          const formattedFormula = formatFormulaToDisplay(field.value);
          const example = calculateSalaryFormula(formattedFormula);
          return (
            <>
              <FormControl fullWidth margin={margin}>
                <TextField
                  {...field}
                  value={field.value || ""}
                  onChange={onInput}
                  multiline
                  required
                  label={t(title)}
                  error={!!errors[name] || !example}
                  helperText={!!errors[name] && t("Please enter formula!")}
                />
              </FormControl>
              {formattedFormula && (
                <>
                  <Typography variant="body2" color="grey" my={2}>
                    {t("example")}:
                  </Typography>
                  <Stack
                    direction="row"
                    gap={1}
                    flexWrap="wrap"
                    alignItems="center"
                  >
                    <Typography>{formattedFormula}</Typography>=
                    <Typography fontWeight={600}>{example}</Typography>
                  </Stack>
                </>
              )}
              <Popper
                open={isHelperOpen}
                anchorEl={inputRef.current}
                role={undefined}
                placement="bottom-start"
                transition
                disablePortal
                sx={{ zIndex: 9 }}
              >
                {({ TransitionProps, placement }) => (
                  <ClickAwayListener onClickAway={toggleHelperOpen}>
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === "bottom-start"
                            ? "left top"
                            : "left bottom",
                      }}
                    >
                      <Paper>
                        <MenuList
                          id="composition-menu"
                          aria-labelledby="composition-button"
                          sx={{
                            maxHeight: 150,
                            overflowY: "auto",
                          }}
                        >
                          {SALARY_SHORTCUTS.map(({ key }) => (
                            <MenuItem
                              key={key}
                              onClick={() => onItemSelect(key)}
                            >
                              {key}
                            </MenuItem>
                          ))}
                        </MenuList>
                      </Paper>
                    </Grow>
                  </ClickAwayListener>
                )}
              </Popper>
            </>
          );
        }}
      />
    </div>
  );
}

export default SalaryFormulaInput;
