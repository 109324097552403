import React, { useCallback, useEffect, useMemo, useState } from "react";

import {
  TextField,
  AutocompleteRenderGroupParams,
  AutocompleteRenderInputParams,
  CircularProgress,
  Typography,
} from "@mui/material";
import t from "translate";
import { useLazyQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "hooks/redux";

import paths from "appConstants/RoutePaths";
import { IStudent } from "interfaces/Groups";
import { addStudent } from "store/reducer/CenterSlice";
import { GET_STUDENTS_AND_APPLY_REQUESTS_FOR_SEARCH } from "./api";

import {
  GroupHeader,
  GroupItems,
  StyledAutocomplete,
} from "./StudentSearchInput.style";
import { ApplyRequestType } from "pages/Center/Admission/types";

import SearchRoundedIcon from "@mui/icons-material/SearchRounded";

interface StudentType extends IStudent {
  __typename: string;
}

interface ApplyRequestWithTypename extends ApplyRequestType {
  __typename: string;
}

type SelectedValue = StudentType | ApplyRequestWithTypename;

function StudentSearchInput() {
  const [searchValue, setSearchValue] = useState("");

  const centerId = useAppSelector((store) => store.center.center?._id);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [getStudentsAndApplyRequestsForSearch, { data, loading }] =
    useLazyQuery(GET_STUDENTS_AND_APPLY_REQUESTS_FOR_SEARCH);

  const students =
    data?.getStudentsAndApplyRequestsForSearch?.payload?.students || [];
  const applyRequests: ApplyRequestWithTypename[] = useMemo(
    () =>
      data?.getStudentsAndApplyRequestsForSearch?.payload?.applyRequests || [],
    [data]
  );

  const handleSelectOption = useCallback(
    (selectedOption: any) => {
      if (selectedOption && selectedOption?.__typename === "Student") {
        dispatch(addStudent(selectedOption));
        navigate(paths.CENTER_STUDENT_INFO);
        return;
      }

      if (selectedOption && selectedOption?.__typename === "ApplyRequest") {
        const foundIndex = applyRequests.findIndex(
          (elem: ApplyRequestType) => elem._id === selectedOption._id
        );

        const page = Math.floor(foundIndex / 10 + 1);
        const queryParams = `?tab=${selectedOption.status}&page=${page}&applyId=${selectedOption._id}&date=${selectedOption.createdAt}`;

        navigate(paths.CENTER_ADMISSION + queryParams);
      }
      setSearchValue("");
    },
    [applyRequests, dispatch, navigate]
  );

  const groupBy = useCallback((option: any) => {
    if (option?.__typename === "ApplyRequest") {
      return t("admission") + ": " + option?.status;
    }
    return t("students");
  }, []);

  const renderInput = useCallback(
    (params: AutocompleteRenderInputParams) => (
      <TextField
        {...params}
        placeholder={t("Input name or phone")}
        sx={{ borderRadius: 2 }}
        InputProps={{
          ...params.InputProps,
          startAdornment: <SearchRoundedIcon color="disabled" />,
          endAdornment: (
            <React.Fragment>
              {loading ? <CircularProgress color="inherit" size={20} /> : null}
              {params.InputProps.endAdornment}
            </React.Fragment>
          ),
        }}
      />
    ),
    [loading]
  );

  const renderGroup = useCallback((params: AutocompleteRenderGroupParams) => {
    return (
      <li key={params.key}>
        <GroupHeader>{params.group}</GroupHeader>
        <GroupItems>{params.children}</GroupItems>
      </li>
    );
  }, []);

  const renderOption = useCallback(
    (props: React.HTMLAttributes<HTMLLIElement>, option: any) => {
      return (
        <li {...props} key={option._id}>
          <div>
            <Typography variant="body1">
              {option?.name || option?.user?.name}
            </Typography>
            <Typography variant="body2" color="GrayText">
              {option?.phone || option?.user?.phone}
            </Typography>
            <Typography variant="body2" color="GrayText">
              {option?.extraPhone}
            </Typography>
          </div>
        </li>
      );
    },
    []
  );

  useEffect(() => {
    const handler = setTimeout(() => {
      getStudentsAndApplyRequestsForSearch({
        variables: {
          center: centerId,
          text: searchValue,
        },
      });
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [searchValue]);

  useEffect(() => {
    if (centerId) {
      getStudentsAndApplyRequestsForSearch({
        variables: {
          center: centerId,
          text: "",
        },
      });
    }
  }, [centerId]);

  if (!centerId) {
    return <></>;
  }

  return (
    <StyledAutocomplete
      clearOnBlur
      groupBy={groupBy}
      renderGroup={renderGroup}
      renderInput={renderInput}
      renderOption={renderOption}
      filterOptions={(x) => x}
      onInputChange={(event, newInputValue, reason) => {
        if (reason !== "reset") {
          setSearchValue(newInputValue);
        }
      }}
      loading={loading}
      options={students.concat(applyRequests)}
      getOptionLabel={(option: any) => option.name}
      sx={{ display: { xs: "none", sm: "block" } }}
      onChange={(_, newValue) => handleSelectOption(newValue as SelectedValue)}
    />
  );
}

export default StudentSearchInput;
