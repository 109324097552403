import React from "react";
import { Box, SvgIconTypeMap, Typography, colors } from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";

import { pxToRem } from "utils";

export interface ContactCardPropsType {
  value: string | number;
  onClick?: VoidFunction;
  icon: OverridableComponent<SvgIconTypeMap<{}, "svg">> & {
    muiName: string;
  };
}

const ContactCard = ({ value, icon: Icon, onClick }: ContactCardPropsType) => {
  return (
    <Box
      gap={2}
      display="flex"
      alignItems="center"
      onClick={onClick}
      sx={{ cursor: "pointer" }}
    >
      <Icon sx={{ fontSize: pxToRem(25), color: colors.blue[700] }} />
      <Typography variant="subtitle1">{value}</Typography>
    </Box>
  );
};

export default ContactCard;
