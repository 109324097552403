import { gql } from "@apollo/client";

export const SIGN_IN = gql`
  query signIn($phone: String!, $password: String!) {
    signIn(user: { phone: $phone, password: $password }) {
      token
      user {
        _id
        name
        email
        phone
        image
        city
        dateOfBirthday
        centers {
          center {
            _id
            name
            description
            thumbnail
            logo
          }
        }
      }
    }
  }
`;

export const GET_USER_BY_ID = gql`
  query getUserById($_id: ID!) {
    getUserById(_id: $_id) {
      payload {
        user {
          _id
          name
          description
          city

          dateOfBirthday
          phone
          email
          image
          centers {
            center {
              _id
              name
              logo
              city
              country
              description
            }
            group {
              title
              _id
              days
              startTime
              endTime
            }
          }
          centers {
            center {
              _id
              name
              description
              logo
            }
          }
        }
        experiences {
          _id
          title
          description
          center {
            _id
            name
            logo
          }
          startedAt
          endedAt
        }
      }
    }
  }
`;
