import { colors } from "@mui/material";
import styled from "styled-components";
import { pxToRem } from "utils";

export default styled.div`
  .verify__container {
    width: 100%;
    height: ${pxToRem(65)};
  }

  .vi__wrapper {
    width: 100%;
    margin-bottom: ${pxToRem(16)};
  }

  .verify__character {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: ${pxToRem(4)};
    border: ${pxToRem(1)} solid #cbcbcb;
    color: #000000de;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: ${pxToRem(28)};
  }

  .verify__character--inactive {
    background-color: #fff;
  }
  .verify__character--selected {
    border-color: transparent;
    color: ${colors.blue[700]};
    outline-color: ${colors.blue[700]};
  }
`;
