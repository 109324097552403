export function pxToRem(px: number): string {
  return px / 16 + "rem";
}

export const acceptedFilesType = ["image/jpg", "image/png", "image/jpeg"];

export const monthsLabel = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const TimeFormat = "HH:mm";
export const DateFormat = "DD.MM.YYYY";
export const DateAndTimeFormat = "DD.MM.YYYY HH:mm";
export const DayFormat = "ddd";

export const removeTypeName = (obj: { [key: string]: any }) => {
  const { __typename, ...fields } = obj;
  const keys = Object.keys(fields);
  const res: any = {};
  keys.forEach((key: string) => {
    if (typeof fields[key] === "object") {
      res[key] = removeTypeName(fields[key]);
    } else {
      res[key] = fields[key];
    }
  });
  return res;
};
