import { colors } from "@mui/material";
import styled from "styled-components";
import { pxToRem } from "utils";
import { ActionTypes } from ".";

export default styled.div<{ type: ActionTypes }>`
  padding: ${pxToRem(16)};
  border-radius: ${pxToRem(8)};
  border: ${pxToRem(1)} solid ${colors.grey[300]};

  @media (max-width: 900px) {
    .card {
      flex-direction: column;
      align-items: flex-start;
    }
  }
`;
