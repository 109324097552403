import { gql } from "@apollo/client";

export const CREATE_SALARY = gql`
  mutation createSalary($data: SalaryInput!, $center: ID!) {
    createSalary(data: $data, center: $center) {
      payload {
        title
        amount
      }
    }
  }
`;
