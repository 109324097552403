import React, { useEffect } from "react";
import { Box, Button, FormControl } from "@mui/material";
import AuthWrapper from "UI/AuthWrapper";
import { Controller, FieldValues, useForm } from "react-hook-form";
import t from "translate";
import PasswordInput from "UI/PasswordInput";
import { useMutation } from "@apollo/client";
import { RESET_PASSWORD } from "./api";
import Loader from "components/Loader";
import { useAppSelector } from "hooks/redux";
import { useNavigate } from "react-router-dom";
import paths from "appConstants/RoutePaths";
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";

function ResetPassword() {
  const navigate = useNavigate();
  const resetData = useAppSelector((state) => state.auth.resetPasswordData);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const [resetPassword, { data, loading }] = useMutation(RESET_PASSWORD);

  const handleReset = (data: FieldValues) => {
    resetPassword({
      variables: {
        newPassword: data?.newPassword,
        token: resetData?.token,
        phone: resetData?.phone,
      },
    });
  };

  useEffect(() => {
    if (!loading && data) {
      navigate(paths.SIGN_IN);
    }
  }, [data, loading]);

  return (
    <AuthWrapper
      title="reset password"
      subText=""
      supLinkText="sign in"
      supLinkPath={paths.SIGN_IN}
      supLinkIcon={<ArrowBackIosRoundedIcon fontSize="small" />}
    >
      {loading && <Loader />}
      <Box
        gap={3}
        display="flex"
        component="form"
        flexDirection="column"
        onSubmit={handleSubmit(handleReset)}
      >
        <Controller
          name="newPassword"
          rules={{
            required: true,
          }}
          control={control}
          render={({ field }) => {
            return (
              <FormControl fullWidth>
                <PasswordInput
                  field={field}
                  errors={errors}
                  label="new password"
                  helperText="Please input your new password!"
                />
              </FormControl>
            );
          }}
        />
        <Button size="large" type="submit" fullWidth variant="contained">
          {t("reset")}
        </Button>
      </Box>
    </AuthWrapper>
  );
}

export default ResetPassword;
