import React from "react";

import { Box, colors, Typography } from "@mui/material";
import DiscountActionButton from "../DiscountCardActionButton";

type Props = {
  title: string;
  percentage: number;
  center: string;
  _id: string;
  refetch: any;
};

function DiscountCard({ percentage, title, _id, center, refetch }: Props) {
  return (
    <Box
      p={4}
      border={`1px solid ${colors.grey[300]}`}
      height="100%"
      display="grid"
      gap={1}
    >
      <Box display="flex" justifyContent="flex-end">
        <DiscountActionButton
          center={center}
          discountId={_id}
          refetch={refetch}
          title={title}
          percentage={percentage}
        />
      </Box>
      <Typography variant="h6" textAlign="center">
        {title}
      </Typography>
      <Typography color="GrayText" textAlign="center">
        {percentage} %
      </Typography>
    </Box>
  );
}

export default DiscountCard;
