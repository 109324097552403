import { combineReducers } from "@reduxjs/toolkit";
import AuthSlice from "./AuthSlice";
import CenterSlice from "./CenterSlice";
import PaginationSlice from "./PaginationSlice";

const rootReducers = combineReducers({
  auth: AuthSlice,
  center: CenterSlice,
  pagination: PaginationSlice,
});

export default rootReducers;
