import { Box, colors, Typography } from "@mui/material";
import React from "react";
import t from "translate";
import { Link } from "react-router-dom";
import { usePermission } from "hooks/permission";

type Props = {
  _id: string;
  title: string;
  description: string;
  numberOfUnits: number;
  unitsType?: string;
  path: string;
  section: string;
};

function RoadmapCard({
  _id,
  title,
  description,
  numberOfUnits,
  path,
  section,
  unitsType,
}: Props) {
  const { isActionAllowed } = usePermission();
  const isViewInfoAllowed = isActionAllowed({
    section,
    action: "view",
  });
  return (
    <Link to={isViewInfoAllowed ? path + _id : "#"}>
      <Box p={4} border={`1px solid ${colors.grey[300]}`} height="100%">
        <Box display="flex" flexDirection="column" gap={1}>
          <Typography variant="h6">{title}</Typography>
          <Typography>
            {numberOfUnits} {t(unitsType || "subjects")}
          </Typography>
          <Typography color="GrayText">{description}</Typography>
        </Box>
      </Box>
    </Link>
  );
}

export default RoadmapCard;
