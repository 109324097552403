import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  ApolloLink,
} from "@apollo/client";
import { store } from "../store";
import { onError } from "@apollo/client/link/error";
import { UNAUTHENTICATED } from "utils/apolloErrorCodes";
import { signOut } from "store/reducer/AuthSlice";
import { removeCenter } from "store/reducer/CenterSlice";
import { toast } from "react-toastify";
import t from "translate";

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_BACKEND_URL,
});

const authMiddleware = new ApolloLink((operation: any, forward: any) => {
  operation.setContext(({ headers = {} }) => {
    return {
      headers: {
        ...headers,
        authorization: store.getState().auth?.token
          ? `Bearer ${store.getState().auth?.token}`
          : null,
      },
    };
  });

  return forward(operation);
});

const errorLink = onError(({ graphQLErrors }) => {
  if (graphQLErrors && graphQLErrors[0].extensions.code === UNAUTHENTICATED) {
    store.dispatch(signOut());
    store.dispatch(removeCenter());
    return;
  }
  if (graphQLErrors) {
    toast.error(t(graphQLErrors[0]?.message));
  }
});

const client = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {
      GroupStudentPayment: {
        keyFields: [
          "payments",
          [
            "paidAt",
            "totalAmount",
            "isPaymentTransferred",
            "monthAsDate",
            "transferFromMonth",
            "month",
          ],
        ],
      },
      GroupStudentAttendancesInfo: {
        keyFields: ["_id", "shouldPayAmount", "totalAttendances"],
      },
      StudentTuitionFeeType: {
        keyFields: ["feeAmount", "sessions", "_id"],
      },

      DebtorStudent: {
        keyFields: [
          "student",
          "user",
          "payments",
          "attendances",
          ["monthlyCharge", "_id", ["date"]],
        ],
      },
    },
  }),
  link: ApolloLink.from([authMiddleware, errorLink, httpLink]),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "cache-and-network",
    },
  },
});

export default client;
