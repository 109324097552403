import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  TextField,
} from "@mui/material";
import React, { useEffect } from "react";
import t from "translate";
import CloseIcon from "@mui/icons-material/Close";
import { Controller, FieldValues, useForm } from "react-hook-form";
import { useMutation } from "@apollo/client";
import { CREATE_ROADMAP } from "./api";
import { useAppSelector } from "hooks/redux";
import Loader from "components/Loader";

type Props = {
  refetch: any;
  open: boolean;
  onClose: VoidFunction;
};

function CreateRoadmap({ refetch, onClose, open }: Props) {
  const [createRoadmap, { data, loading }] = useMutation(CREATE_ROADMAP);

  const centerId = useAppSelector((state) => state.center.center?._id);

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();

  const onSubmit = ({ title, description }: FieldValues) => {
    createRoadmap({
      variables: {
        title,
        description,
        center: centerId,
      },
    });
  };

  const handleClose = () => {
    reset({});
    onClose();
  };

  useEffect(() => {
    if (!loading && data) {
      refetch();
      handleClose();
    }
  }, [data, loading]);

  return (
    <>
      {loading && <Loader />}
      <Dialog open={open} onClose={handleClose}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <DialogTitle>{t("Create roadmap")}</DialogTitle>
          <IconButton aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <DialogContent>
          <Box display="flex" gap={2} flexDirection="column">
            <Controller
              name="title"
              rules={{
                required: true,
              }}
              control={control}
              render={({ field }) => {
                return (
                  <FormControl fullWidth>
                    <TextField
                      required
                      {...field}
                      value={field.value || ""}
                      label={t("Roadmap title")}
                      error={!!errors[field.name]}
                      helperText={
                        !!errors[field.name] &&
                        t("Please input the roadmap title!")
                      }
                    />
                  </FormControl>
                );
              }}
            />
            <Controller
              name="description"
              rules={{
                required: true,
              }}
              control={control}
              render={({ field }) => {
                return (
                  <FormControl fullWidth>
                    <TextField
                      required
                      {...field}
                      multiline
                      rows={6}
                      value={field.value || ""}
                      label={t("Roadmap description")}
                      error={!!errors[field.name]}
                      helperText={
                        !!errors[field.name] &&
                        t("Please input the roadmap descriptions!")
                      }
                    />
                  </FormControl>
                );
              }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            fullWidth
            color="inherit"
            onClick={handleClose}
            variant="contained"
          >
            {t("cancel")}
          </Button>
          <Button
            fullWidth
            variant="contained"
            onClick={handleSubmit(onSubmit)}
          >
            {t("create")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default CreateRoadmap;
