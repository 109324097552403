import React, { useCallback, useEffect } from "react";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { useMutation } from "@apollo/client";
import { useAppSelector } from "hooks/redux";
import { Controller, FieldValues, useForm } from "react-hook-form";

import t from "translate";
import Loader from "components/Loader";
import { CREATE_DISCOUNT } from "./api";
import NumberInput from "UI/NumberInput";

import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";
import { RefetchType } from "interfaces";

type Props = {
  open: boolean;
  refetch: RefetchType;
  onClose: VoidFunction;
};

function CreateDiscountModal({ refetch, onClose, open }: Props) {
  const [createDiscount, { data, loading }] = useMutation(CREATE_DISCOUNT);

  const centerId = useAppSelector((state) => state.center.center?._id);

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm();

  const onSubmit = useCallback(
    ({ title, percentage }: FieldValues) => {
      createDiscount({
        variables: {
          title,
          percentage: +percentage,
          center: centerId,
        },
      });
    },
    [centerId, createDiscount]
  );

  const handleClose = useCallback(() => {
    reset({});
    onClose();
  }, [onClose, reset]);

  useEffect(() => {
    if (!loading && data) {
      toast.success(t("Discount created successfully"));
      refetch();
      handleClose();
    }
  }, [data, loading]);

  return (
    <>
      {loading && <Loader />}
      <Dialog open={open} onClose={handleClose}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <DialogTitle>{t("Create discount")}</DialogTitle>
          <IconButton aria-label="close" onClick={handleClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <DialogContent>
          <Controller
            name="title"
            rules={{
              required: true,
            }}
            control={control}
            render={({ field }) => {
              return (
                <FormControl fullWidth>
                  <TextField
                    required
                    {...field}
                    value={field.value || ""}
                    label={t("Discount title")}
                    error={!!errors[field.name]}
                    helperText={
                      !!errors[field.name] &&
                      t("Please input the discount title!")
                    }
                  />
                </FormControl>
              );
            }}
          />
          <Controller
            name="percentage"
            rules={{
              required: true,
              max: 100,
              min: 0,
            }}
            control={control}
            render={({ field }) => {
              return (
                <FormControl fullWidth margin="normal">
                  <NumberInput
                    field={field}
                    label={t("Percentage")}
                    errors={errors}
                    helperText={t("Please input correct percentage!")}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                    }}
                  />
                </FormControl>
              );
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            fullWidth
            color="inherit"
            onClick={handleClose}
            variant="contained"
          >
            {t("cancel")}
          </Button>
          <Button
            fullWidth
            variant="contained"
            onClick={handleSubmit(onSubmit)}
          >
            {t("create")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default CreateDiscountModal;
