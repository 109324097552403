import { gql } from "@apollo/client";

export const UPDATE_DISCOUNT = gql`
  mutation updateDiscount(
    $center: ID!
    $title: String!
    $percentage: Float!
    $discountId: ID!
  ) {
    updateDiscount(
      center: $center
      title: $title
      percentage: $percentage
      discountId: $discountId
    ) {
      payload {
        _id
      }
    }
  }
`;
