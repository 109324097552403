import React, {
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Box,
  colors,
  Divider,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import { useMutation } from "@apollo/client";
import Loader from "components/Loader";
import { usePermission } from "hooks/permission";
import { toast } from "react-toastify";
import t from "translate";

import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import ConfirmModal from "components/ConfirmModal";
import { DELETE_ROOM } from "../../api";
import UpdateRoomModal from "../UpdateModal";

type Props = { refetch: any; roomId: string; center: string; title: string };

function RoomActionButton({ refetch, center, roomId, title }: Props) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isDeleteModalVisible, setIsDeleteModalVisible] =
    useState<boolean>(false);
  const [isUpdateModalVisible, setIsUpdateModalVisible] =
    useState<boolean>(false);

  const { isActionAllowed } = usePermission();

  const [deleteRoom, { data, loading }] = useMutation(DELETE_ROOM);

  const handleMoreClick = useCallback((event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleMenuClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const toggleDeleteModalVisible = useCallback(
    () => setIsDeleteModalVisible((prev) => !prev),
    []
  );
  const toggleUpdateModalVisible = useCallback(
    () => setIsUpdateModalVisible((prev) => !prev),
    []
  );

  const isDeleteAllowed = useMemo(
    () =>
      isActionAllowed({
        section: "rooms",
        action: "delete",
      }),
    [isActionAllowed]
  );
  const isEditAllowed = useMemo(
    () =>
      isActionAllowed({
        section: "rooms",
        action: "edit",
      }),
    [isActionAllowed]
  );

  const handleDelete = useCallback(() => {
    deleteRoom({
      variables: {
        roomId,
        center,
      },
    });
  }, [roomId, center, deleteRoom]);

  useEffect(() => {
    if (!loading && data) {
      refetch();
      toast.success(t("Room deleted successfully!"));
      toggleDeleteModalVisible();
    }
  }, [data, loading]);

  if (!isDeleteAllowed && !isEditAllowed) {
    return <></>;
  }

  return (
    <>
      {loading && <Loader />}
      <IconButton size="small" onClick={handleMoreClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        open={!!anchorEl}
        id="basic-menu"
        anchorEl={anchorEl}
        onClose={handleMenuClose}
        MenuListProps={{
          "aria-labelledby": "student-action-menu",
        }}
        transformOrigin={{ horizontal: "center", vertical: "top" }}
        anchorOrigin={{ horizontal: "center", vertical: "bottom" }}
      >
        {isEditAllowed && (
          <Box>
            <MenuItem sx={{ gap: 1 }} onClick={toggleUpdateModalVisible}>
              <EditOutlinedIcon color="warning" />
              <Typography color={colors.orange[800]}>{t("edit")}</Typography>
            </MenuItem>
            <Divider />
          </Box>
        )}
        {isDeleteAllowed && (
          <MenuItem sx={{ gap: 1 }} onClick={toggleDeleteModalVisible}>
            <DeleteOutlinedIcon color="error" />
            <Typography color={colors.red[800]}>{t("delete")}</Typography>
          </MenuItem>
        )}
      </Menu>
      <ConfirmModal
        onConfirm={handleDelete}
        open={isDeleteModalVisible}
        onClose={toggleDeleteModalVisible}
      />
      <UpdateRoomModal
        onClose={toggleUpdateModalVisible}
        refetch={refetch}
        open={isUpdateModalVisible}
        title={title}
        roomId={roomId}
      />
    </>
  );
}

export default RoomActionButton;
