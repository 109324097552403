import { gql } from "@apollo/client";

export const CREATE_TOPIC = gql`
  mutation createTopic($data: CreateTopicInput!, $center: ID!) {
    createTopic(center: $center, data: $data) {
      payload {
        _id
      }
    }
  }
`;
