import { createSlice } from "@reduxjs/toolkit";
import { IUser } from "interfaces/Redux";

interface ResetPasswordDataType {
  phone: string;
  token: string;
  resetPasswordToken: string;
}

interface SignUpDataType {
  token: string;
  user: IUser | null;
}

interface IState {
  token: string;
  user: IUser | null;
  lang?: {
    id: string;
    name: string;
    version: number;
    words: { [key: string]: string };
  };
  resetPasswordData: ResetPasswordDataType | null;
  signUpData: SignUpDataType | null;
}

const initialState: IState = {
  token: "",
  user: null,
  lang: {
    id: "",
    name: "",
    version: 0,
    words: {},
  },
  resetPasswordData: null,
  signUpData: null,
};

const auth = createSlice({
  name: "auth",
  initialState,
  reducers: {
    signInAction(state: IState, action) {
      state.token = action.payload?.token;
      state.user = action.payload.user;
    },
    signOut(state) {
      state.token = "";
      state.user = null;
    },
    setUser(state, action) {
      state.user = action.payload;
    },
    setLang(state, action) {
      state.lang = {
        id: action.payload.id,
        name: action.payload.name,
        version: action?.payload?.version,
        words: action.payload.words,
      };
    },
    setResetPasswordData(state, action) {
      state.resetPasswordData = action.payload;
    },
    setSignUpData(state, action) {
      state.signUpData = action.payload;
    },
  },
});

export default auth.reducer;

export const {
  signInAction,
  signOut,
  setUser,
  setLang,
  setResetPasswordData,
  setSignUpData,
} = auth.actions;
