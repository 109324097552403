import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import SendOutlinedIcon from "@mui/icons-material/SendOutlined";

import { ContactCardPropsType } from "../components/ContactCard";

export const contactItems: ContactCardPropsType[] = [
  {
    value: "+998 88 522 22 88",
    icon: CallOutlinedIcon,
  },
  {
    value: "@alitechsupport",
    icon: SendOutlinedIcon,
    onClick: () => (window.location.href = "https://t.me/alitechsupport"),
  },
];
