import Header from "components/Header";
import { usePermission } from "hooks/permission";
import { useAppDispatch, useAppSelector } from "hooks/redux";
import { Suspense, useEffect, useState } from "react";
import { Navigate, Route, Routes, useSearchParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { centerRoutes, privateRoutes, publicRoutes } from "routes";
import NoData from "UI/NoData";
import Loader from "components/Loader";
import { useLazyQuery } from "@apollo/client";
import { GET_DEFAULT_LANGUAGE } from "graphql/Query/Language";
import { setLang } from "store/reducer/AuthSlice";
import { decryptHash } from "helpers/crypto";
import { addCenter } from "store/reducer/CenterSlice";
import Sidebar from "components/Sidebar";
import InnerLoader from "components/InnerLoader";

function App() {
  const [allowedCenterRoutes, setAllowedCenterRoutes] = useState<any[]>([]);
  const token = useAppSelector((state) => state.auth.token);
  const lang = useAppSelector((store) => store.auth.lang);
  const permissions = useAppSelector((store) => store.center.permission);

  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const centerParamId = searchParams.get("id");

  const { PermissionRoutes, loading, isSectionsExist } = usePermission();

  const [getLang, { data: langData, loading: langLoading }] =
    useLazyQuery(GET_DEFAULT_LANGUAGE);

  useEffect(() => {
    getLang({
      variables: {
        version: lang?.version || 1,
        langId: lang?.id,
      },
    });

    if (centerParamId !== "null" && centerParamId) {
      const decryptedHash = decryptHash(
        centerParamId?.replaceAll(" ", "+") || ""
      );

      if (decryptedHash) {
        dispatch(addCenter({ _id: decryptedHash }));
      }
    } else {
      searchParams.delete("id");
    }
  }, []);

  useEffect(() => {
    if (!langLoading && langData) {
      const langPayload = langData.getDefaultLanguage?.payload;
      if (langPayload?.words) {
        const lang = {
          id: langPayload?._id,
          name: langPayload?.lang,
          words: langPayload?.words,
          version: langPayload?.version,
        };
        dispatch(setLang(lang));
      }
    }
  }, [langLoading, langData, dispatch]);

  useEffect(() => {
    if (!loading && isSectionsExist) {
      const allowedRoutes = PermissionRoutes(centerRoutes);
      setAllowedCenterRoutes(allowedRoutes);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, isSectionsExist, permissions]);

  if (token) {
    return (
      <>
        <ToastContainer />
        <Header />
        {loading && <Loader />}
        <Sidebar>
          <Suspense fallback={<InnerLoader />}>
            <Routes>
              {privateRoutes.map((route) => (
                <Route
                  element={route.component}
                  path={route.path}
                  key={route.key}
                />
              ))}
              {!loading &&
                !!allowedCenterRoutes.length &&
                allowedCenterRoutes.map((route) => (
                  <Route
                    element={route.component}
                    path={route.path}
                    key={route.key}
                  />
                ))}
              {!allowedCenterRoutes.length && loading ? (
                <></>
              ) : (
                <Route
                  element={
                    <NoData title="Not Found" style={{ paddingTop: 56 }} />
                  }
                  path="*"
                />
              )}
            </Routes>
          </Suspense>
        </Sidebar>
      </>
    );
  }
  return (
    <>
      <ToastContainer />
      <Routes>
        {publicRoutes.map((route) => (
          <Route element={route.component} path={route.path} key={route.key} />
        ))}
        <Route
          path="*"
          element={<Navigate to={`/?id=` + centerParamId} replace />}
        />
      </Routes>
    </>
  );
}

export default App;
