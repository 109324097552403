export const formatNumberInput = (value: string) => {
  const sanitizedInput = (value + "")
    .replace(/\s/g, "")
    .replace(/[^0-9.]/g, "");
  const [integerPart, decimalPart] = sanitizedInput.split(".");
  const localizedInteger = Number(integerPart).toLocaleString("fi-FI");
  return `${localizedInteger}${
    decimalPart
      ? "." + decimalPart.slice(0, 3)
      : sanitizedInput.endsWith(".")
      ? "."
      : ""
  }`;
};
