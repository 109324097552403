import { colors } from "@mui/material";
import styled from "styled-components";
import { pxToRem } from "utils";

const AuthWrapperStyle = styled.div`
  min-height: 100vh;

  .auth-wrapper__block {
    width: 50%;
    padding: ${pxToRem(10)};
  }

  .wrapper {
    height: 100%;
  }

  .form-side {
    width: 100%;
    max-width: ${pxToRem(400)};
  }

  .auth__sign-up-link {
    margin-left: ${pxToRem(5)};
    font-weight: 600;
    white-space: nowrap;
  }

  .auth-wrapper__slider-side {
    top: 0;
    display: flex;
    position: sticky;
    min-height: 100vh;
    text-align: center;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background: ${colors.blue[800]};
  }

  .slide {
    height: 90vh;
    display: flex !important;
    flex-direction: column;
    justify-content: center;
  }

  .auth__image {
    width: ${pxToRem(450)};
  }

  @media (max-width: 992px) {
    .auth__image {
      width: ${pxToRem(400)};
    }
  }

  @media (max-width: 992px) {
    .auth__image {
      width: ${pxToRem(350)};
    }
  }

  @media (max-width: 720px) {
    .auth__image {
      width: ${pxToRem(300)};
    }
  }

  @media (max-width: 620px) {
    .wrapper {
      flex-direction: column !important;
    }

    .auth-wrapper__slider-side {
      display: none;
    }

    .auth-wrapper__block {
      width: 100%;
      height: 100vh;
      padding: ${pxToRem(30)} ${pxToRem(10)};
    }
  }
`;

export default AuthWrapperStyle;
