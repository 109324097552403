import { useEffect } from "react";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { DatePicker } from "@mui/x-date-pickers-pro";
import { Controller, FieldValues, useForm } from "react-hook-form";
import { Box, Button, FormControl, TextField } from "@mui/material";

import t from "translate";
import { SIGN_UP } from "./api";
import Loader from "components/Loader";
import PhoneInput from "UI/PhoneInput";
import paths from "appConstants/RoutePaths";
import AuthWrapper from "UI/AuthWrapper";
import { useAppDispatch } from "hooks/redux";
import CitySelect from "components/CitySelect";
import { signInAction } from "store/reducer/AuthSlice";
import { formatPhoneToSubmit } from "UI/PhoneInput/helper";
import PasswordInput from "UI/PasswordInput";

function SignUp() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [signUpQuery, { data, loading }] = useMutation(SIGN_UP);

  const handleSignUp = (data: FieldValues) => {
    const { dateOfBirthday, phone, ...user } = data;

    signUpQuery({
      variables: {
        user: {
          ...user,
          phone: formatPhoneToSubmit(phone),
          dateOfBirthday: dateOfBirthday.toString(),
        },
      },
    });
  };

  useEffect(() => {
    if (!loading && data) {
      const res = data?.signUp || {};
      dispatch(
        signInAction({
          token: res.token,
          user: res.user,
        })
      );
      navigate(paths.PROFILE);
    }
  }, [data, loading]);

  return (
    <>
      {loading && <Loader />}
      <AuthWrapper
        title={"sign up"}
        subLink={"sign in"}
        subLinkPath={paths.SIGN_IN}
        subText="do you have an account?"
      >
        <Box
          gap={3}
          display="flex"
          component="form"
          flexDirection="column"
          onSubmit={handleSubmit(handleSignUp)}
        >
          <Controller
            name="name"
            rules={{ required: true }}
            control={control}
            render={({ field }) => (
              <FormControl fullWidth>
                <TextField
                  label={t("full name")}
                  {...field}
                  error={!!errors[field.name]}
                  helperText={
                    !!errors[field.name] && t("Please input your full name!")
                  }
                />
              </FormControl>
            )}
          />
          <Controller
            name="dateOfBirthday"
            rules={{ required: true }}
            control={control}
            render={({ field }) => (
              <FormControl fullWidth>
                <DatePicker
                  {...field}
                  disableFuture
                  format="DD/MM/YYYY"
                  label={t("date of birth")}
                  slotProps={{
                    textField: {
                      error: !!errors[field.name],
                      helperText:
                        !!errors[field.name] && t("Select your date of birth!"),
                    },
                  }}
                />
              </FormControl>
            )}
          />
          <CitySelect control={control} errors={errors} />
          <Controller
            name="phone"
            rules={{
              required: true,
            }}
            control={control}
            render={({ field }) => {
              return (
                <FormControl fullWidth>
                  <PhoneInput field={field} errors={errors} />
                </FormControl>
              );
            }}
          />
          <Controller
            name="password"
            rules={{ required: true }}
            control={control}
            render={({ field }) => (
              <FormControl fullWidth>
                <PasswordInput
                  field={field}
                  errors={errors}
                  helperText={`${
                    !!errors[field.name] && t("Please input password!")
                  }`}
                />
              </FormControl>
            )}
          />
          <Button fullWidth size="large" type="submit" variant="contained">
            {t("sign up")}
          </Button>
        </Box>
      </AuthWrapper>
    </>
  );
}

export default SignUp;
