import { gql } from "@apollo/client";

export const UPDATE_SUBJECT = gql`
  mutation updateSubject($title: String!, $subjectId: ID!, $center: ID!) {
    updateSubject(center: $center, title: $title, subjectId: $subjectId) {
      payload {
        _id
      }
    }
  }
`;
