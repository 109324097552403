import { gql } from "@apollo/client";

export const GET_DEFAULT_LANGUAGE = gql`
  query getDefaultLanguage($version: Int, $langId: ID) {
    getDefaultLanguage(version: $version, langId: $langId) {
      payload {
        _id
        lang
        words
        version
      }
    }
  }
`;
