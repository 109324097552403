import { gql } from "@apollo/client";

const GET_ROADMAP = gql`
  query getRoadmapById($center: ID!, $roadmapId: ID!) {
    getRoadmapById(center: $center, roadmapId: $roadmapId) {
      payload {
        title
        description
        subjectsAmount
      }
    }
  }
`;

export default GET_ROADMAP;
