import { Box, colors, Typography } from "@mui/material";
import React from "react";
import t from "translate";
import RoomActionButton from "../RoomCardActionButton";

type Props = {
  title: string;
  numberOfGroups: number;
  center: string;
  _id: string;
  refetch: any;
};

function RoomCard({ numberOfGroups, title, _id, center, refetch }: Props) {
  return (
    <Box
      p={4}
      border={`1px solid ${colors.grey[300]}`}
      height="100%"
      display="grid"
      gap={1}
    >
      <Box display="flex" justifyContent="flex-end">
        <RoomActionButton
          center={center}
          roomId={_id}
          refetch={refetch}
          title={title}
        />
      </Box>
      <Typography variant="h6">{title}</Typography>
      <Typography color="GrayText">
        {numberOfGroups} {t("group")}
      </Typography>
    </Box>
  );
}

export default RoomCard;
