import { Button } from "@mui/material";
import React, { useCallback, useState } from "react";
import t from "translate";
import PayModal from "./PayModal";

function CenterPaymentContactButton() {
  const [isPayModalVisible, setIsPayModalVisible] = useState<boolean>(false);

  const togglePayModalVisible = useCallback(
    () => setIsPayModalVisible((prev) => !prev),
    []
  );
  return (
    <>
      <Button variant="contained" onClick={togglePayModalVisible}>
        {t("pay")}
      </Button>
      {isPayModalVisible && (
        <PayModal onClose={togglePayModalVisible} open={isPayModalVisible} />
      )}
    </>
  );
}

export default React.memo(CenterPaymentContactButton);
