import CryptoJS from "crypto-js";

export const encryptToHash = (message: string) => {
  const encryptedMessage = CryptoJS.Rabbit.encrypt(
    message,
    process.env.REACT_APP_CRYPTO_SECRET_KEY as string
  );

  return encryptedMessage.toString();
};

export const decryptHash = (hash: string) => {
  const bytes = CryptoJS.Rabbit.decrypt(
    hash,
    process.env.REACT_APP_CRYPTO_SECRET_KEY as string
  );

  return bytes.toString(CryptoJS.enc.Utf8);
};
