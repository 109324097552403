import styled from "styled-components";
import { pxToRem } from "utils";

export default styled.div`
  width: 100%;
  max-width: ${pxToRem(1440)};
  margin: 0 auto;
  padding: ${pxToRem(50)} ${pxToRem(15)};

  @media print {
    max-width: 100%;
  }
`;
