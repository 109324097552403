import { gql } from "@apollo/client";

export const UPDATE_TOPIC = gql`
  mutation updateTopic(
    $title: String
    $description: String
    $topicId: ID!
    $center: ID!
  ) {
    updateTopic(
      center: $center
      title: $title
      description: $description
      topicId: $topicId
    ) {
      payload {
        _id
      }
    }
  }
`;
