import { useMutation } from "@apollo/client";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputAdornment,
  TextField,
} from "@mui/material";
import Loader from "components/Loader";
import { UPDATE_SALARY } from "../../api";
import { useCallback, useEffect, useState } from "react";
import { Controller, FieldValues, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import t from "translate";
import { ISalary, SalaryVariantsType } from "interfaces/Salary";
import NumberInput from "UI/NumberInput";
import SalaryTypeCard from "../SalaryTypeCard";
import SalaryShortcutsList from "components/SalaryShortcuts";
import SalaryFormulaInput from "components/SalaryFormulaInput";
import { useAppSelector } from "hooks/redux";

interface Props {
  open: boolean;
  toggleModalVisible: VoidFunction;
  refetch: any;
  salary?: ISalary;
}

function UpdateSalary({ open, toggleModalVisible, refetch, salary }: Props) {
  const [salaryType, setSalaryType] = useState<SalaryVariantsType>(
    salary?.type || "fixed"
  );

  const centerId = useAppSelector((store) => store.center.center?._id);

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm();

  const [updateSalary, { data, loading }] = useMutation(UPDATE_SALARY);

  const handleCancel = useCallback(() => {
    reset({});
    toggleModalVisible();
  }, [reset, toggleModalVisible]);

  const handleFinish = useCallback(
    ({ title, amount, formula }: FieldValues) => {
      const data = {
        title,
        type: salaryType,
        formula: "",
        amount: 0,
      };

      if (salaryType === "formula") {
        data.formula = formula;
      } else {
        let normalizedAmount = Math.floor(parseInt(amount));
        if (salaryType === "percent") {
          normalizedAmount =
            parseInt(amount) > 100
              ? 100
              : parseInt(amount) < 0
              ? 10
              : Math.floor(parseInt(amount));
        }
        data.amount = normalizedAmount;
      }

      updateSalary({
        variables: {
          salaryId: salary?._id,
          center: centerId,
          data,
        },
      });
    },
    [salary, salaryType, centerId, updateSalary]
  );

  useEffect(() => {
    if (salary) {
      reset({
        title: salary.title,
        amount: salary.amount,
        formula: salary.formula,
      });
      setSalaryType(salary.type);
    }
  }, [salary]);

  useEffect(() => {
    if (!loading && data) {
      handleCancel();
      refetch();
      toast.success(t("Salary updated successfully!"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, loading]);

  return (
    <>
      {loading && <Loader />}
      <Dialog open={open} onClose={handleCancel}>
        <DialogTitle>{t("update salary")}</DialogTitle>
        <DialogContent>
          <Box gap={3} display="flex" flexDirection="column">
            <Box gap={2} display="flex">
              <SalaryTypeCard
                onSelect={setSalaryType}
                selectedType={salaryType}
                type="fixed"
              />
              <SalaryTypeCard
                onSelect={setSalaryType}
                selectedType={salaryType}
                type="percent"
              />
              {/* <SalaryTypeCard
                onSelect={setSalaryType}
                selectedType={salaryType}
                type="formula"
              /> */}
            </Box>
            <Controller
              name="title"
              rules={{
                required: true,
              }}
              control={control}
              render={({ field }) => {
                return (
                  <FormControl fullWidth>
                    <TextField
                      label={t("title")}
                      {...field}
                      error={!!errors[field.name]}
                      helperText={
                        !!errors[field.name] && t("Please input title!")
                      }
                    />
                  </FormControl>
                );
              }}
            />

            {salaryType !== "formula" && (
              <Controller
                name="amount"
                rules={{
                  required: true,
                }}
                control={control}
                render={({ field }) => {
                  return (
                    <FormControl fullWidth>
                      <NumberInput
                        field={field}
                        label="amount"
                        errors={errors}
                        helperText="Please input amount!"
                        InputProps={{
                          endAdornment:
                            salaryType === "percent" ? (
                              <InputAdornment position="end">%</InputAdornment>
                            ) : (
                              <InputAdornment position="end">
                                sum
                              </InputAdornment>
                            ),
                          inputProps:
                            salaryType === "percent"
                              ? { max: 100, min: 10 }
                              : {},
                        }}
                      />
                    </FormControl>
                  );
                }}
              />
            )}

            {salaryType === "formula" && (
              <SalaryFormulaInput
                control={control}
                errors={errors}
                name="formula"
                title="Enter salary formula"
              />
            )}
          </Box>
          {salaryType === "formula" && <SalaryShortcutsList />}
        </DialogContent>
        <DialogActions>
          <Button
            fullWidth
            color="inherit"
            variant="contained"
            onClick={handleCancel}
          >
            {t("cancel")}
          </Button>
          <Button
            fullWidth
            variant="contained"
            onClick={handleSubmit(handleFinish)}
          >
            {t("update")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default UpdateSalary;
