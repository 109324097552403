import React, { ReactNode, useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/client";
import { Controller, FieldValues, useForm } from "react-hook-form";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  Typography,
} from "@mui/material";

import t from "translate";
import { CHECK_PASSWORD } from "./api";
import Loader from "components/Loader";
import PasswordInput from "UI/PasswordInput";
import { useAppSelector } from "hooks/redux";

interface Props {
  children: ReactNode;
  onConfirm: VoidFunction;
}

const ConfirmPassword = ({ children, onConfirm }: Props) => {
  const [isConfirmModalVisible, setIsConfirmModalVisible] =
    useState<boolean>(false);

  const phone = useAppSelector((state) => state?.auth?.user?.phone);

  const {
    reset,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [signIn, { loading, data }] = useLazyQuery(CHECK_PASSWORD);

  const toggleConfirmModalVisible = () =>
    setIsConfirmModalVisible((prev) => !prev);

  const handleConfirm = ({ password }: FieldValues) => {
    signIn({
      variables: {
        phone,
        password,
      },
      fetchPolicy: "network-only",
    });
  };

  useEffect(() => {
    if (!loading && data) {
      reset({});
      onConfirm();
      toggleConfirmModalVisible();
    }
  }, [data, loading]);

  return (
    <>
      {loading && <Loader />}
      <div onClick={toggleConfirmModalVisible}>{children}</div>
      <Dialog open={isConfirmModalVisible} onClose={toggleConfirmModalVisible}>
        <DialogContent>
          <Box gap={2} display="flex" flexDirection="column">
            <Box>
              <Typography variant="h6" gutterBottom>
                {t("confirm password")}
              </Typography>
              <Typography variant="body2">
                {t("Please confirm your password before continuing")}
              </Typography>
            </Box>
            <Controller
              name="password"
              rules={{
                required: true,
              }}
              control={control}
              render={({ field }) => {
                return (
                  <FormControl fullWidth>
                    <PasswordInput field={field} errors={errors} />
                  </FormControl>
                );
              }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            fullWidth
            color="inherit"
            variant="contained"
            onClick={toggleConfirmModalVisible}
          >
            {t("cancel")}
          </Button>
          <Button
            fullWidth
            variant="contained"
            onClick={handleSubmit(handleConfirm)}
          >
            {t("confirm")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ConfirmPassword;
