import { Box, Button } from "@mui/material";
import React, { ReactNode } from "react";
import ErrorImage from "./assets/error.png";
import { pxToRem } from "utils";
import t from "translate";
import Container from "UI/Container";

interface Props {
  children?: ReactNode;
}

interface State {
  error: boolean;
}

class ErrorBoundary extends React.Component<Props, State> {
  state = { error: false };

  static getDerivedStateFromError(error: any) {
    return { error: error };
  }

  componentDidCatch(error: any, errorInfo: any) {
    console.log(error, errorInfo);
  }

  resetErrorHandler = () => {
    this.setState({ error: false });
  };

  render() {
    if (this.state.error) {
      return (
        <Container style={{ height: "100vh" }}>
          <Box
            display="flex"
            height="100%"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
          >
            <img
              src={ErrorImage}
              alt="empty-box"
              style={{
                width: "100%",
                height: "100%",
                maxWidth: pxToRem(480),
                maxHeight: pxToRem(480),
              }}
            />
            <Button
              size="large"
              variant="contained"
              onClick={this.resetErrorHandler}
            >
              {t("Back to Home")}
            </Button>
          </Box>
        </Container>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
