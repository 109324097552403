import React, { useEffect } from "react";
import { Box, Button, FormControl } from "@mui/material";
import AuthWrapper from "UI/AuthWrapper";
import PhoneInput from "UI/PhoneInput";
import Loader from "components/Loader";
import { Controller, FieldValues, useForm } from "react-hook-form";
import t from "translate";
import { useMutation } from "@apollo/client";
import { RESET_PASSWORD_PHONE_CONFIRM } from "./api";
import { useDispatch } from "react-redux";
import { setResetPasswordData } from "store/reducer/AuthSlice";
import paths from "appConstants/RoutePaths";
import { useNavigate } from "react-router-dom";
import { formatPhoneToSubmit } from "UI/PhoneInput/helper";
import ArrowBackIosRoundedIcon from "@mui/icons-material/ArrowBackIosRounded";

function ResetPasswordPhone() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    control,
    getValues,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [resetPasswordPhoneConfirm, { data, loading }] = useMutation(
    RESET_PASSWORD_PHONE_CONFIRM
  );

  const handlePhoneVerification = (data: FieldValues) => {
    data.phone = formatPhoneToSubmit(data?.phone);

    resetPasswordPhoneConfirm({
      variables: {
        phone: data?.phone,
      },
    });
  };

  useEffect(() => {
    if (!loading && data) {
      const token = data?.resetPasswordPhoneConfirm?.payload?.token;
      dispatch(
        setResetPasswordData({
          token,
          phone: formatPhoneToSubmit(getValues()?.phone),
        })
      );
      navigate(paths.RESET_VERIFY_CODE);
    }
  }, [data, loading]);

  return (
    <AuthWrapper
      title="forgot your password?"
      supLinkText="sign in"
      supLinkPath={paths.SIGN_IN}
      supLinkIcon={<ArrowBackIosRoundedIcon fontSize="small" />}
    >
      {loading && <Loader />}
      <Box
        gap={3}
        display="flex"
        component="form"
        flexDirection="column"
        onSubmit={handleSubmit(handlePhoneVerification)}
      >
        <Controller
          name="phone"
          rules={{
            required: true,
            min: 9,
          }}
          control={control}
          render={({ field }) => {
            return (
              <FormControl fullWidth>
                <PhoneInput field={field} errors={errors} />
              </FormControl>
            );
          }}
        />
        <Button size="large" type="submit" fullWidth variant="contained">
          {t("send code")}
        </Button>
      </Box>
    </AuthWrapper>
  );
}

export default ResetPasswordPhone;
