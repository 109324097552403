import { InputAdornment, TextField } from "@mui/material";
import React from "react";
import {
  ControllerRenderProps,
  FieldErrors,
  FieldValues,
} from "react-hook-form";
import t from "translate";
import { formatPhoneNumber } from "./helper";

type Props = {
  field: ControllerRenderProps<FieldValues, any>;
  errors: FieldErrors<FieldValues>;
  title?: string;
};

function PhoneInput({ field, errors, title }: Props) {
  return (
    <TextField
      label={t(title || "phone")}
      {...field}
      value={formatPhoneNumber(field.value)}
      InputProps={{
        startAdornment: <InputAdornment position="start">+998</InputAdornment>,
      }}
      placeholder="(00) 000-00-00"
      error={!!errors[field.name]}
      helperText={
        !!errors[field.name] && t("Please input correct phone number!")
      }
    />
  );
}

export default PhoneInput;
