import { gql } from "@apollo/client";

export const RESET_PASSWORD_PHONE_CONFIRM = gql`
  mutation resetPasswordPhoneConfirm($phone: String!) {
    resetPasswordPhoneConfirm(phone: $phone) {
      payload {
        token
      }
    }
  }
`;
