import { gql } from "@apollo/client";

export const SIGN_UP = gql`
  mutation signUp($user: SignupInput!) {
    signUp(user: $user) {
      token
      user {
        _id
        name
        email
        phone
        image
        city
        dateOfBirthday
        centers {
          center {
            _id
            name
            description
            thumbnail
            logo
          }
        }
      }
    }
  }
`;
