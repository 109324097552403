import { gql } from "@apollo/client";

export const GET_SUBJECTS = gql`
  query getSubjects($center: ID!, $roadmapId: ID!) {
    getSubjects(center: $center, roadmapId: $roadmapId) {
      payload {
        _id
        title
        numberOfTopics
      }
    }
  }
`;
