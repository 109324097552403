import React from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Typography,
  colors,
} from "@mui/material";

import t from "translate";
import { contactItems } from "./constants";
import ContactCard from "./components/ContactCard";

interface Props {
  open: boolean;
  onClose: VoidFunction;
}

const PayModal = ({ open, onClose }: Props) => {
  return (
    <Dialog
      open={open}
      maxWidth="xs"
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
    >
      <DialogContent>
        <DialogTitle>{t("Contact us to make payment!")}</DialogTitle>
        <Typography variant="body2" color={colors.grey[400]} mb={2}>
          {t(
            "Payment is currently not working on the website. Contact us to make a payment to the center"
          )}
        </Typography>
        <Box gap={2} display="flex" flexDirection="column">
          {contactItems?.map(({ icon, value, onClick }) => (
            <ContactCard icon={icon} value={value} onClick={onClick} />
          ))}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default PayModal;
