import { useMutation } from "@apollo/client";
import { Box } from "@mui/material";
import ActionCard from "components/ActionCard";
import { usePermission } from "hooks/permission";
import React, { useCallback, useEffect, useState } from "react";
import { DELETE_SUBJECT } from "./api";
import Loader from "components/Loader";
import { toast } from "react-toastify";
import t from "translate";
import { useNavigate } from "react-router-dom";
import paths from "appConstants/RoutePaths";
import UpdateSubject from "./components/Update";

type Props = { subjectId: string; center: string; title: string; refetch: any };

function Actions({ center, subjectId, title, refetch }: Props) {
  const [isUpdateModalVisible, setIsUpdateModalVisible] = useState(false);

  const [deleteSubject, { data, loading }] = useMutation(DELETE_SUBJECT);

  const { PermissionComponents } = usePermission();
  const navigate = useNavigate();

  const handleDelete = () => {
    deleteSubject({
      variables: {
        center,
        subjectId,
      },
    });
  };

  const toggleUpdateModal = useCallback(() => {
    setIsUpdateModalVisible((prev) => !prev);
  }, []);

  useEffect(() => {
    if (!loading && data) {
      toast.success(t("Subject deleted successfully!"));
      navigate(paths.CENTER_ADDITIONS);
    }
  }, [data, loading]);

  return (
    <>
      {loading && <Loader />}
      <Box display="grid" gap={2}>
        <PermissionComponents section="subjects" action="edit">
          <ActionCard
            type="warning"
            btnLabel="edit"
            title="Update subject"
            onClick={toggleUpdateModal}
            description="update subject title for make changes."
          />
        </PermissionComponents>
        <PermissionComponents section="subjects" action="delete">
          <ActionCard
            type="danger"
            btnLabel="delete"
            title="Delete subject"
            onClick={handleDelete}
            description="If you delete the subject, all of the related topics will also be deleted."
          />
        </PermissionComponents>
      </Box>
      <UpdateSubject
        _id={subjectId}
        center={center}
        title={title}
        refetch={refetch}
        onClose={toggleUpdateModal}
        open={isUpdateModalVisible}
      />
    </>
  );
}

export default Actions;
